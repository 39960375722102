.f-titreTexte {
  margin-top: 61px;
  margin-bottom: 67px;

  opacity: 0;
  transform: translateY(100px);
  transition: transform 0.35s ease,
    opacity 0.35s ease;

  &.blocInView {
    opacity: 1;
    transform: translateY(0);
  }


  &.detail_offre {
    margin-top: 25px;
  }

  &_secondtitre {
    font-size: 30px;
    font-weight: 900;
    line-height: 1em;
    font-style: oblique;
    margin-bottom: 25px;
    color: $purple;
  }

  &_text {
    font-size: 20px;
    line-height: 1.38em;
  }

  @include m1023 {
    margin-top: 27px;
    margin-bottom: 0;

    &_secondtitre {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 20px;
    }

    &_text {
      font-size: 16px;
      line-height: 1.38em;
    }
  }
}