.headerType1 {
    position: relative;
    margin-bottom: 93px;

    @include headerBack;
    @include headerInterne;
    
    &_box {
        padding-top: 5.6%;
        @include headerCases;
    }

    @include headerCasesInterne1;

    &_titre {
        @include headerTitre;
        font-size: 45px;
        font-size: 3.294vw;
        margin: 48px 20px 0px 42px;
        opacity: 1;
        transform: none;
        width: 69%;
        line-height: 1em;

        @include m1761 {
            font-size: 58px
        }
    }

    &_scroll {
        @include headerScroll;
        @include headerScrollInterne;
        position: absolute;
        bottom: 18px;
        left: 52px;
        opacity: 1;
        transform: none;

        &:after {
            opacity: 1;
            transform: translateY(0) rotate(90deg);
        }
    }

    @include m1023 {
        margin-bottom: 0;
        &_box {
            padding-top: 0;
        }

        &_titre {
            font-size: 28px;
            font-size: 8.75vw;
            margin: 50% 6.3% 0 6.3%;
            width: 72%;
        }
    }
}