.footer {
    display: flex;
    height: 25vw;
    overflow: hidden;

    &>* {
        flex: 0 0 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        opacity: 0;
        transform: translateY(50px);
        transition: opacity 0.35s ease, transform 0.35s ease;
        @include delayTransition(0.175s, 0s, 5);
    }


    &.blocInView>* {
        opacity: 1;
        transform: translateY(0);
    }

    &_address {
        background-color: $purple;
        color: #fff;
        font-size: 16px;
        text-align: center;

        &_logo {
            width: 114px;
            margin-bottom: 22px;
        }

        p:last-child {
            margin-top: 17px;
        }
    }

    &_liens {
        background-color: $black;
        color: $white;
        font-size: 16px;

        ul {
            width: 76%;

            li {
                margin: 22px 0;

                a {
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        width: 0%;
                        height: 1px;
                        background-color: $white;
                        transition: width 0.35s ease;
                    }

                    &:hover:after {
                        width: 100%;
                    }
                }
            }
        }
    }

    &_socials {
        background-color: $gray;
        // border-top: solid 1px $black;
        flex-direction: row;
        a {
            padding: 0 9px;
            img {
                height: 26px;
                width: 26px;
            }
        }
    }

    &_scrolltotop {
        background-color: $white;
        // border-top: solid 1px $black;
        a,
        button {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                //flex: 0 0;
            }
        }
    }

    @include m991 {
        .f-5cases+& {
            margin-top: 117px;
        }

        height: auto;
        flex-wrap: wrap;

        &>* {
            flex: 0 0 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50vw;
            border-top: none;
            :not(.footer_socials) {
                flex-direction: column;
            }
        }
    }

    @include m479 {
        height: auto;
        flex-wrap: wrap;

        &_address,
        &_liens {
            flex: 0 0 100%;
            height: 100vw;
        }

        &_socials,
        &_scrolltotop {
            flex: 0 0 50%;
            height: 50vw;
            border: none;
        }

        &_liens {
            order: 5;
            padding-top: 6px;
            padding-bottom: 90px;

            ul {
                width: 87%;
            }
        }
    }
}