.f-doubleImgCarreRect {
  padding: 30px 0 50px;

  &-container {
    display: flex;
    justify-content: center;

    &_imgCarre {
      width: 500px;
      height: 500px;
      object-fit: cover;

      &__border {
        position: absolute;
        top: 0;
        width: calc(100vw - 40px);
        height: calc(100vw - 40px);
        max-width: 500px;
        max-height: 500px;
        background-image: radial-gradient(transparent calc(70.71% - 2px),
            #7d146c calc(70.71% - 0px));
      }
    }

    &_imgRect {
      width: 500px;
      height: 664px;
      object-fit: cover;
    }

    &>* {
      opacity: 0;
      transform: translateY(100px);
      transition: opacity 0.35s ease, transform 0.35s ease;
      @include delayTransition(0.35s, 0s, 2);

      .blocInView & {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @include m1023 {
    padding: 0px 0 35px;

    .container--notfull {
      margin: 0;
    }

    &-container {
      flex-direction: column;

      &_imgCarre {
        width: 76.5625vw;
        height: 76.5625vw;
        object-fit: cover;

        &__border {
          position: absolute;
          top: 0;
          width: 76.5625vw;
          height: 76.5625vw;
          max-width: none;
          max-height: none;
          background-image: radial-gradient(transparent calc(70.71% - 2px),
              #7d146c calc(70.71% - 0px));
        }
      }

      &_imgRect {
        width: 76.5625vw;
        height: 100vw;
        object-fit: cover;
        align-self: flex-end;
      }
    }
  }
}