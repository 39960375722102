@import 'node_modules/sass-math/math';

$animation_duration_clip : 0.7s;
$animation_duration: 0.35s;

$margin_left : 260px;
$nb_spot: 20;
$pi: 3.1415926536;
$easeIn : ease;
$easeInReverse :cubic-bezier(0.4, -1.73, 1, 0.32);


@function makepolygon($decalage, $margin) {
  $val: 100% 0%,
  calc(#{$margin}) 0;

  @for $i from 1 through $nb_spot {
    $percent: ($i - 1) / ($nb_spot - 1);
    $val: #{$val},
    calc(#{$decalage * sin(-180deg * $percent)} + #{$margin}) #{$percent * 100%};
  }

  $val: #{$val},
  calc(#{$margin}) 100%,
  100% 100%;

  @return $val;
}

@mixin clippath($decalage :0, $margin: 0) {
  clip-path: polygon(makepolygon($decalage, $margin));
}

.f-offers {
  margin-top: 59px;

  @include m1023 {
    margin-top: 42px;
  }

  &_title {
    @include m1099 {
      font-size: 30px;
      line-height: 0.93;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 1179px) {
      margin-left: 25vw;
    }

    margin-top: 10px;
    margin-bottom: 50px;
    position: relative;
    font-size: 50px;
    font-weight: 900;
    line-height: 1.2;
    font-style: oblique;

    &::before {
      position: absolute;
      width: 75px;
      height: 75px;
      top: -10px;
      left: -100px;
      content: "";
      background: transparent url('../../assets/img/square-red.svg') no-repeat center center;
      background-size: contain;
    }
  }

  &_search {
    @include m1099 {
      font-size: 22px;
      line-height: 1.27;
      padding-bottom: 8px;
    }

    @include m1024 {
      margin-bottom: 30px;
    }

    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.35s ease,
    transform 0.35s ease;
    @include delayTransition(0.35s, 0.175s, 1);

    .blocInView & {
      opacity: 1;
      transform: translateY(0);
    }

    padding-bottom: 11px;
    font-size: 30px;
    font-weight: 900;
    font-style: oblique;
    line-height: 1.2;
    color: $purple;
    border-bottom: 2px solid $purple;
    position: relative;

    &::after {
      @include m1099 {
        top: -8px;
        right: 2px;
      }

      position: absolute;
      width: 30px;
      height: 30px;
      top: 0;
      right: 0;
      content: "";
      background: transparent url('../../assets/img/icon-filter.svg') no-repeat center center;
      background-size: contain;
    }

    &_button {
      cursor: pointer;

      @include m1099 {
        bottom: 27%;
        font-size: 12px;
        margin-right: -23px;
      }

      position: absolute;
      bottom: 27%;
      right: 0;
      margin-right: -15px;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 40px;
      z-index: 1;
      width: 100px;
      height: 30px;
      font-style: normal;
      font-weight: 300;
    }
  }

  &_content {
    margin-bottom: 30px;

    @include m1023 {
      margin-bottom: 24px;
    }
  }

  &_list {
    @include m1024 {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &_item {
    flex: 0 0 27.6%;
    margin-top: 17px;
    margin-bottom: 15px;
    
    @include m1024 {
      margin-top: 20px;
      margin-bottom: 28px;
      margin-left: 8.6%;

      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }

    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.35s ease,
    transform 0.35s ease;
    @include delayTransition(0.175s, 0.35s, 15);

    .blocInView & {
      opacity: 1;
      transform: translateY(0);
    }

    &_container {
      border-bottom: 1px solid black;
      padding-bottom: 50px;
      @include m1023 {
        padding-bottom: 18px;
        border-color: grey;
      }
    }

    &_date {
      font-size: 12px;
      margin-bottom: 2px;
      line-height: 1.92;
      font-weight: 500;
      color: #767676;

      @include m1024 {
        font-size: 14px;
        line-height: 1.64;
        margin-bottom: 9px;
      }
    }

    &_job {
      font-size: 18px;
      line-height: 1.222222em;
      font-weight: 900;
      font-style: oblique;
      margin-bottom: 4px;

      @include m1024 {
        font-size: 23px;
        line-height: 1.26;
        margin-bottom: 3px;
      }
    }

    &_status,
    &_location {
      font-size: 16px;
      line-height: 1.22;
      margin-bottom: 2px;

      @include m1024 {
        font-size: 20px;
        line-height: 1.26;
        margin-bottom: 4px;
      }
    }

    &_link {
      width: 50%;
      font-family: $fontText;
      font-weight: 600;
      margin-top: 25px;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1.64;
      color: $purple;

      span {
        position: relative;

        &::before {
          position: absolute;
          width: 40px;
          height: 40px;
          top: -10px;
          right: -40px;
          content: "";
          background: transparent url('../../assets/img/icon-arrow.svg') no-repeat center center;
          background-size: contain;
        }
      }

      @include m1023 {
        margin-top: 14px;

        span {
          &::before {
            top: -12px;
            right: -38px;
          }
        }
      }
    }
  }

  &_more {
    margin-bottom: 64px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    @include m1024 {
      margin-bottom: 172px;
    }

    &--disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  &_filtersMenu {
    $filtersMenu: &;
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.35s ease-in;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.open {
      transform: translateX(0);
    }

    width: calc(768px + #{$margin_left});
    @include m1023 {
      width: calc(100% + #{$margin_left});
    }

    &.open &_container {
      transition: transform $animation_duration ease, clip-path $animation_duration_clip $easeIn;
      @include clippath(0px, $margin_left);
    }
    @include m1024 {
      &_bg {
        pointer-events: none;
        position: fixed;
        z-index: 10;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $black;
        opacity: 0;
        transition: opacity 0.35s ease-in;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &.open {
          opacity: .8;
        }
      }

    }

    &_container {
      height: auto;
      background-color: $purple;
      color: $white;
      padding-bottom: 32px;
      transition: transform #{$animation_duration} ease,
      clip-path #{$animation_duration} $easeInReverse;
      @include clippath($margin_left, $margin_left);
      padding-left: $margin_left;
      @include m1024 {
        width: 1028px;
        height: 100%;
        position: fixed;
        overflow: auto;
      }
    }

    &_close {
      position: absolute;
      top: 30px;
      right: 30px;

      @include m1024 {
        margin-right: 80px;
        top: 45px;
        right: 15px
      }

      &_line_top {
        height: 10px;
        width: 2px;
        margin-left: 5px;
        background-color: $white;
        transform: rotate(45deg);
        z-index: 1;

        @include m1024 {
          height: 40px;
        }
      }

      &_line_bottom {
        height: 10px;
        width: 2px;
        background-color: $white;
        transform: rotate(90deg);
        z-index: 2;

        @include m1024 {
          height: 40px;
        }
      }
    }

    &_categories {
      padding: 60px 0 40px 0;
      
      @include m1024 {
        display: flex;
        margin-bottom: 18px;
        padding: 20px 0 0 100px;

        &_group {
          margin: 0 32px 32px 0;
          width: 176px;

          >div:first-child {
            padding-bottom: 40px;
            border-bottom: 1px solid $white;
          }
        }
      }
    }

    &_category {
      margin-top: 30px;

      @include m1023 {
        border-top: 1px solid white;
        margin: 0 54px 0 0;
        padding: 18px 0 25px 28px;
        font-size: 16px;
      }

      font-size: 20px;

      &_title {
        margin-bottom: 25px;
        font-size: 20px;
        font-weight: bold;
        font-style: oblique;
        line-height: 1.1;
        text-transform: uppercase;

        @include m1024 {
          font-size: 23px;
          line-height: 1.09;
          margin-bottom: 20px;
        }
      }
    }

    &_checkbox {
      display: block;
      position: relative;
      padding-left: 30px;

      &:not(:last-child) {
        margin-bottom: 12px;

        @include m768 {
          margin-bottom: 14px;
        }
      }

      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked~#{$filtersMenu}_checkmark::after {
          display: block;
        }
      }
    }

    &_checkmark {
      position: absolute;
      top: -2px;
      left: 0;
      height: 20px;
      width: 20px;
      border: 2px solid white;

      @include m768 {
        top: 2px;
      }

      &::after {
        content: "";
        position: absolute;
        display: none;
        top: 1px;
        left: 1px;
        width: 14px;
        height: 14px;
        border: 2px solid $purple;
        background-color: $white;
      }
    }

    &_filter {
      text-align: right;
      margin-right: 24px;
      height: 60px;
      display: flex;
      justify-content: flex-end;

      @include m1024 {
        margin-right: 82px;
      }
    }

    &_button {
      position: relative;

      &:before {
        content: "";
        width: 60px;
        height: 60px;
        background: transparent url('../../assets/img/icon-arrow-white.svg') no-repeat center center;
        background-size: contain;
        position: absolute;
        top: 0;
        right: 0;
      }

      @include m1024 {
        &:hover {
          background-color: $white;
          border-radius: 30px;
        }
      }

      &_title {
        font-size: 20px;
        font-weight: bold;
        font-style: oblique;
        line-height: 1.1;
        text-transform: uppercase;
        max-width: 130px;
        display: block;
        margin-right: 60px;
        color: $white;
      }
    }
  }
}