.f-grillephotos {
    margin-bottom: 97px;
    margin-top: 61px;

    &_titre {
        font-size: 35px;
        font-style: oblique;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 49px;
    }

    &_content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
    }

    &_article {
        display: flex;
        flex: 0 0 48.5%;
        margin-bottom: 29px;
        justify-content: space-between;

        opacity: 0;
        transform: translateY(50px);
        transition: opacity 0.35s ease, transform 0.35s ease;
        @include delayTransition(0.35s, 0s);

        .blocInView & {
            opacity: 1;
            transform: translateY(0);
        }

        &_subarticle {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: right;
            margin: 0 0 0;
        }

        @include m1024 {

            &:nth-child(4n+1) &_subarticle,
            &:nth-child(4n+2) &_subarticle {
                order: 2;
                text-align: left;
            }

            &:nth-child(4n+3) &_subarticle,
            &:nth-child(4n+4) &_subarticle {
                flex: 0 0 37.2%;
                margin-left: 20.4%;
            }

            &:nth-child(4n+1) &_subarticle,
            &:nth-child(4n+2) &_subarticle {
                flex: 0 0 36.2%;
            }
        }


        &_date {
            color: $purple;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 3px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                height: 1px;
                width: 100%;
                background-color: $purple;
                left: calc(100% + 4px);
                right: auto;
                top: 11px;
            }
        }

        @include m1024 {

            &:nth-child(4n+1) &_date,
            &:nth-child(4n+2) &_date {
                &:before {
                    left: auto;
                    right: calc(100% + 4px);
                }
            }
        }

        &_titre {
            font-size: 18px;
            font-weight: 600;
            font-style: oblique;
            line-height: 1.3em;
        }

        &_visuel {
            &Container {
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                overflow: hidden;
            }

            img {
                width: 100%;
                max-width: none;
                height: 100%;
                position: absolute;
                object-fit: cover;
                z-index: 1;
            }
        }

        @include m1024 {

            &:nth-child(4n+3) &_visuel,
            &:nth-child(4n+4) &_visuel {
                flex: 0 0 36.2%;
            }

            &:nth-child(4n+1) &_visuel,
            &:nth-child(4n+2) &_visuel {
                flex: 0 0 57.6%;
            }
        }
    }

    &_bouton {
        margin: 23px 0 0;
        display: flex;
        justify-content: flex-end;
    }

    @include m1023 {
        margin-bottom: 30px;
        margin-top: 30px;

        &_titre {
            font-size: 28px;
            width: 200px;
            line-height: 1em;
            margin: 0 $marginNotFull 35px;
        }

        &_content {
            flex-direction: column;
            align-items: initial;
        }

        &_article {
            margin-bottom: 21px;
            width: 100%;
            display: none;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                display: flex;
            }

            &_subarticle {
                flex: 0 0 40.6%;
                margin-top: 0;
            }

            &:nth-child(2n) &_subarticle {
                text-align: right;
                margin-left: 6%;
            }

            &:nth-child(2n+1) &_subarticle {
                order: 2;
                text-align: left;
                margin-right: 6%;
            }

            &_date {
                margin-bottom: 4px;
            }

            &:nth-child(2n) &_date {
                &:before {
                    left: calc(100% + 4px);
                    right: auto;
                }
            }

            &:nth-child(2n+1) &_date {
                &:before {
                    left: auto;
                    right: calc(100% + 4px);
                }
            }

            &_titre {
                font-size: 15px;
                line-height: 1.15em;
            }

            &_visuel {
                flex: 0 0 46.6%;
            }
        }

        &_bouton {
            margin: 25px 24px 0 0;
        }
    }
}