.header {
    background-color: red;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;

    &_logo {
        position: absolute;
        top: 31px;
        left: 84px;
    }

    @include m1023 {
        &_logo {
            top: 22px;
            left: 20px;
            width: 76px;
        }
    }
}