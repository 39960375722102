html {

    #tarteaucitronRoot #tarteaucitronAlertBigContent {
        max-width: none;
    }

    div#tarteaucitronRoot.tarteaucitronBeforeVisible:before {
        display: none;
    }

    body #tarteaucitronRoot div#tarteaucitronAlertBig {
        position: fixed;
        bottom: 0;
        top: auto;
        transform: none;
        left: 0;
        right: 0;
        width: auto;
        border-radius: 0px;
        background-color: #fff;
        align-items: center;
        padding: 0 90px 0 0;
        z-index: 4;
    }

    body #tarteaucitronRoot.tarteaucitronBeforeVisible div#tarteaucitronAlertBig {
        display: flex !important;
    }

    #tarteaucitronRoot span#tarteaucitronDisclaimerAlert {
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        margin: 29px 0 29px 59px;
        text-align: left;
        color: #000;
    }

    #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
    #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
        font-family: $fontText;
    }

    div#tarteaucitronAlertBig:before {
        display: none;
    }

    #tarteaucitronAlertBig #tarteaucitronCloseAlert,
    #tarteaucitronAlertBig #tarteaucitronPersonalize,
    #tarteaucitronRoot .tarteaucitronDeny {
        background-color: #000;
        height: 44px;
        font-size: 13px !important;
        white-space: nowrap;
        font-weight: 600;
        font-family: $fontText !important;
        border-radius: 5px;
        padding: 0 25px;
        box-shadow: none;
        margin-left: 17px;
    }

    #tarteaucitronAlertBig #tarteaucitronCloseAlert {
        background-color: transparent;
        text-decoration: underline;
        color: #000;
    }

    #tarteaucitronRoot #tarteaucitronAlertBig span.tarteaucitronCross,
    #tarteaucitronRoot #tarteaucitronAlertBig span.tarteaucitronCheck {
        display: none;
    }

    #tarteaucitron {
        width: 830px;
    }

    div#tarteaucitronServices {
        padding: 26px 48px;
    }

    #tarteaucitron #tarteaucitronClosePanel {
        right: 18px;
        top: 51px;
        background-color: transparent;
    }

    #tarteaucitronRoot .tarteaucitronH1 {
        font-weight: 900;
        font-size: 30px;
        color: $purple;
        line-height: 36px;
        margin-bottom: 11px;
    }

    #tarteaucitronRoot div#tarteaucitronInfo {
        color: $purple;
        font-size: 14px !important;
        line-height: 22px !important;
        padding-bottom: 64px !important;
    }

    #tarteaucitron #tarteaucitronClosePanel:hover {
        background-color: transparent;
    }

    #tarteaucitronClosePanel:after {
        color: $purple;
        font-size: 80px;
        width: auto;
    }

    #tarteaucitron #tarteaucitronInfo .tarteaucitronLinkInfos {
        font-weight: 700;
        font-size: 14px;
        color: $purple;
        border-bottom: solid 3px $purple;
        text-decoration: none;
        padding-bottom: 6px;
        display: inline-block;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine {
        border: none;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 5px 0 !important;
    }
    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .titleCookie {
        font-size: 16px;
        color: #7D146C;
        margin-top: 0px;
        margin-bottom: 3px;
        background: transparent;
        display: block;
        font-weight: 700;
        padding: 5px 10px 5px 0;
        text-align: left;
        width: auto;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
        white-space: nowrap;
    }

    #tarteaucitron .tarteaucitronName a {
        background-color: #000;
        font-size: 13px !important;
        white-space: nowrap;
        font-weight: 600;
        font-family: $fontText !important;
        border-radius: 5px;
        padding: 15px 26px;
        display: inline-block;
        box-shadow: none;
        color: #fff;
        margin-right: 16px;
    }

    #tarteaucitronRoot #tarteaucitron .tarteaucitronMainLine .tarteaucitronName {
        margin: 67px 0 20px;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        position: absolute;
    }

    #englobe {
        position: relative;
        width: 100%;
    }
    #tarteaucitronRoot #tarteaucitron .tarteaucitronMainLine .tarteaucitronAsk {
        margin: 0 !important;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, 50%);
    }

    #tarteaucitronRoot .tarteaucitronMainLine .tarteaucitronH2 {
        font-size: 20px;
        color: $purple;
    }

    #tarteaucitronRoot .tarteaucitronTitle {
        font-size: 20px;
        color: $purple;
        margin-top: 0px;
        margin-bottom: 3px;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
        font-size: 14px;
        line-height: 22px;
        border-bottom: none;
        max-width: 70%;
        padding-bottom: 13px !important;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
        margin-bottom: 29px;
    }

    .tarteaucitronCheckBoxBt {
        width: 54px;
        height: 29px;

        &:after {
            left: 4px;
            top: 4px;
            width: 21px;
            height: 21px;
        }
    }

    .tarteaucitronServiceInputCheckbox:checked+.tarteaucitronLabelCheckBox .tarteaucitronCheckBoxBt:after {
        left: calc(50% + 2px);
    }

    #tarteaucitronRoot .denyTxt {
        margin-right: 14px;
    }

    #tarteaucitronAlertBig #tarteaucitronPersonalize:hover, #tarteaucitron #tarteaucitronPrivacyUrl:hover {
        background: $purple;
    }

    @include m1024 {
        #tarteaucitronAlertButtons {
            flex-shrink: 0;
        }
    }

    @include m1023 {
        body #tarteaucitronRoot div#tarteaucitronAlertBig {
            padding: 0;
        }

        #tarteaucitronRoot #tarteaucitronAlertBigContent {
            flex-direction: column;
            width: 100%;
        }

        #tarteaucitronRoot span#tarteaucitronDisclaimerAlert {
            margin: 52px 17px 10px 17px;
        }

        #tarteaucitronAlertButtons {
            flex-wrap: wrap;
            margin-bottom: 30px;
            padding-left: 17px;
        }

        #tarteaucitronAlertBig #tarteaucitronCloseAlert {
            flex: 0 0 100%;
            text-align: left;
            margin-left: 0;
            padding-left: 0;
            margin-bottom: 19px;
        }

        #tarteaucitronAlertBig #tarteaucitronPersonalize {
            margin-left: 0;
            margin-right: 12px;
        }

        div#tarteaucitronServices {
            margin-top: 12px !important;
            margin-left: 17px;
            margin-right: 17px;
            padding: 57px 22px 57px 26px;
        }

        #tarteaucitronRoot div#tarteaucitron {
            z-index: 4;
        }

        #tarteaucitron #tarteaucitronClosePanel {
            right: 21px;
            top: 30px;
        }

        #tarteaucitronRoot .tarteaucitronH1 {
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 13px;
        }

        #tarteaucitronRoot .tarteaucitronMainLine .tarteaucitronH2 {
            line-height: 30px;
        }

        #tarteaucitronRoot #tarteaucitron .tarteaucitronMainLine .tarteaucitronName {
            margin: 58px 0 20px;
            white-space: normal;
        }

        #tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
            max-width: 100%;
            padding-bottom: 16px !important;
        }

        #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
            white-space: normal;
        }

        #tarteaucitron .tarteaucitronName a {
            margin-bottom: 20px;
        }

        #tarteaucitron #tarteaucitronServices .tarteaucitronLine {
            flex-direction: column;
            align-items: flex-start;
        }

        #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
            position: relative;
            margin-left: auto;
            margin-top: 29px;
            margin-bottom: 4px;
        }

        #tarteaucitronRoot #tarteaucitron .tarteaucitronMainLine .tarteaucitronAsk {
            margin: 17px 0 36px 0 !important;
            margin-left: auto !important;
        }

        #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
            display: flex;
        }
    }
}