.f-titreTexteImg {
  margin-top: 58px;
  margin-bottom: 108px;

  &--slider {
    margin-bottom: 161px;
    margin-top: 81px;
  }

  &-container {
    display: flex;
    justify-content: space-between;

    &-left {
      flex-direction: row-reverse;
    }

    &-right {
      flex-direction: row;
    }

    &-imageSeuleright {
      justify-content: flex-end;
    }

    &-imageSeuleleft {
      justify-content: flex-start;
    }

    &-img {
      position: relative;
      width: 50%;
      height: 0;
      padding-bottom: 50%;

      &Absolute {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &-img__border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: radial-gradient(transparent calc(70.71% - 2px),
          #7d146c calc(70.71% - 0px));
    }

    &-textes {
      width: 38.2%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &_secondtitre {
        font-size: 30px;
        font-weight: 900;
        line-height: 1em;
        font-style: oblique;
        margin-bottom: 30px;
        color: $purple;
      }

      &_content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 28px;
      }

      &_text {
        font-size: 20px;
        line-height: 1.3em;
        margin-bottom: 0px;

        li {
          display: flex;
          margin-bottom: 25.4px;
        }

        li::before {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: $purple;
          margin-right: 15px;
          margin-top: 7px;
          padding-right: 12px;
          padding-top: 5px;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // peut-etre rajouter des props pour bien fit avec l'image
    }

    &-slider {
      width: 50%;
      height: 0;
      padding-bottom: 50%;

      &_boxAbsolute {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .slick-next {
        display: none !important;
      }

      .slick-prev {
        display: none !important;
      }

      .slick-list {
        width: 100%;
        height: 450px;
      }

      .slick-dots {
        bottom: -55px;

        li {
          margin: 0;
        }
      }

      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: $purple;
        font-size: 20px;
      }

      .slick-dots li button:before {
        font-size: 8px;
      }

      .slick-slider,
      .slick-list,
      .slick-track {
        height: 100%;
      }

      .slick-slider {
        position: absolute;
        width: 100%;
      }

      .slick-slide {
        &>div {
          width: 100%;
          height: 100%;
        }

        .f-titreTexteImg-container-slider_boxAbsolute--slider {
          position: relative;
        }
      }

      img {
        object-fit: contain;
        border: solid 1px $white;
      }
    }

    &>* {
      opacity: 0;
      transform: translateY(100px);
      transition: transform 0.35s ease,
        opacity 0.35s ease;
      @include delayTransition(0.35s, 0s, 2);

      .blocInView & {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &_button {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }

  @include m1023 {
    margin-top: 32px;
    margin-bottom: 30px;

    &_button {
      margin-top: 24px;
      margin-bottom: 30px;
    }

    &-container {
      flex-direction: column;

      &-img {
        width: 100%;
        padding-bottom: 100%;
      }

      &-left &-img {
        margin-bottom: 20px;
      }

      &-textes {
        width: 100%;

        &_secondtitre {
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 22px;
        }

        &_text {
          font-size: 16px;
          line-height: 1.38em;
          margin-bottom: 30px;
        }
      }

      &-left &-textes_text {
        margin-bottom: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: solid 1px $white;
        margin-bottom: 35px;
        align-self: center;
        // peut-etre rajouter des props pour bien fit avec l'image
      }

      &-slider {
        width: 100%;
        padding-bottom: 100%;
        height: 0;
        align-self: center;
        margin-bottom: 45px;
        margin-top: 20px;

        @include m1023 {
          margin-bottom: 30px;
          margin-top: 30px;
        }

        .slick-dots {
          bottom: -42px;

          li {
            margin: 0;
          }
        }

        .slick-dots li.slick-active button:before {
          font-size: 13px;
        }

        .slick-dots li button:before {
          font-size: 5px;
        }
      }
    }

    &--left {
      margin-right: auto;
      margin-left: -20px;
      width: 76.5625vw;
      height: 76.5625vw;
      padding-bottom: 0;
    }

    &--right {
      margin-left: auto;
      margin-right: -20px;
      width: 76.5625vw;
      height: 76.5625vw;
      padding-bottom: 0;
    }

    &--center_with_marge {
      margin-right: auto;
      margin-left: auto;
      padding-right: 0px;
      padding-left: 0px;
    }

    &--center_without_marge {
      margin-left: -20px;
      padding-bottom: 0;
      width: 100vw;
      height: 100vw;
    }
  }

  @include m1023 {
    &--only_top {
      margin-bottom: 0;

      &+.flexible {
        margin-top: 0 !important;
      }
    }
  }
}