.headerType3 {
    position: relative;
    margin-bottom: 93px;

    @include headerBack;

    &_box {
        padding-top: 5.65%;
        @include headerCases;
    }

    &_categorie {
        display: none;
    }

    &_backlink {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 21px 68px;
        letter-spacing: -0.03em;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            width: 17px;
            height: 13px;
            left: -25px;
            top: 1px;
            mask-image: url('/assets/img/picto_fleche_small.svg');
            mask-position: center center;
            mask-size: contain;
            mask-repeat: no-repeat;
            background-color: $black;
        }
    }

    &_date {
        font-size: 14px;
        color: $pink;
        font-weight: 500;
        margin: 39px 20px 0px 48px;
    }

    &_contrat,
    &_lieu {
        font-size: 16px;
        color: $pink;
        margin: 0px 20px 0px 38px;
        padding: 0 1.5%;
    }

    &_titre,
    &_rubrique {
        @include headerTitre;
        font-size: 50px;
        font-size: 3.66vw;
        margin: 0px 20px 0px 38px;
        opacity: 1;
        transform: none;
        width: auto;
        line-height: 1.11em;

        @include m1761 {
            font-size: 64.46px
        }
    }

    &_titre {
        padding-bottom: 2%;
    }

    &_rubrique {
        font-size: 45px;
        font-size: 3.294vw;
        margin: 47px 20px 0px 43px;
        line-height: 1.025em;

        @include m1761 {
            font-size: 58px
        }
    }

    &_scroll {
        @include headerScroll;
        @include headerScrollInterne;
        position: absolute;
        bottom: 18px;
        left: 52px;
        opacity: 1;
        transform: none;

        &:after {
            opacity: 1;
            transform: translateY(0) rotate(90deg);
        }
    }

    &_thumbnail {
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -1;
        position: relative;
    }

    &_postuler {
        position: fixed;
        z-index: 1;
        text-transform: uppercase;
        background-color: $black;
        border-radius: 30px;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        right: 80px;
        bottom: 50px;
        font-size: 20px;
        font-style: oblique;
        font-weight: 700;
        padding: 0 52px 0 29px;

        &:before {
            content: "";
            width: 24px;
            height: 16px;
            position: absolute;
            right: 20px;
            top: 22px;
            background-color: $white;
            mask-image: url(/assets/img/arrowCtaCarresWhite.svg);
            mask-repeat: no-repeat;
            mask-position: center center;
            mask-size: contain;
        }

        opacity: 0;
        transform: translateX(100px);
        transition: transform 0.75s ease 0.75s,
        color 0.35s ease,
        background 0.35s ease,
        opacity 0.75s ease 0.75s;

        .blocInView & {
            opacity: 1;
            transform: translateX(0);
        }

        &:hover {
            background-color: $white;
            color: $black;

            &:before {
                background-color: $black;
            }
        }
    }

    &_scrollMobile {
        display: none;
    }

    @include m1023 {
        margin-bottom: 33px;

        &_box {
            padding-top: 0;
            flex-direction: column;
            align-items: flex-start;

            &_square1 {
                width: 100%;

                &_content {
                    background-color: $purple;
                }
            }

            &_square2 {
                width: 100%;

                &_content {
                    background: transparent;
                }
            }
        }

        &_infos {
            background-color: $black;
            width: 77vw;
            height: 77.1vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        &_categorie {
            display: block;
            font-size: 28px;
            font-size: 8.75vw;
            font-weight: 900;
            font-style: oblique;
            color: $white;
            margin-bottom: 13.6%;
            margin-left: 8%;
            margin-right: 8%;
        }

        &_backlink {
            color: $white;
            font-weight: 600;
            margin: 0 0 5.4% calc(8% + 26px);
            letter-spacing: 0;

            &:before {
                background-color: $white;
            }
        }

        &_date {
            font-size: 14px;
            margin: 0;
            padding: 22px 20px 0px 20px;
        }

        &_titre {
            font-size: 28px;
            font-size: 8.75vw;
            line-height: 1.05em;
            margin: 2.3% 4.9% 0 4.9%;
            padding-bottom: 2%;
        }

        &_contrat,
        &_lieu {
            margin: 0;
            padding: 4px 20px 0px 20px;
        }

        &_postuler {
            border-radius: 22px;
            height: 43px;
            right: auto;
            left: 50%;
            transform: translateX(100px) translateX(-50%);
            bottom: 18px;
            font-size: 16px;
            padding: 0 41px 0 22px;
            white-space: nowrap;

            &:before {
                width: 18px;
                height: 12px;
                right: 12px;
                top: 15px;
                background-image: url(/assets/img/arrowCtaCarresWhiteMobile.svg);
            }

            .blocInView & {
                opacity: 1;
                transform: translateX(-50%);
            }
        }

        &_scrollMobile {
            display: block;
            width: 24px;
            height: 16px;
            position: absolute;
            right: 11px;
            bottom: 22px;
            background: transparent url(/assets/img/arrowCtaCarresWhiteMobile.svg) no-repeat center center;
            background-size: contain;
            transform: rotate(90deg);
        }
    }
}