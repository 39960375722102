$fontPath: '../../assets/fonts/';

/*
@font-face {
    font-family: 'icomoon';
    src: url('#{$fontPath}Icomoon/icomoon.eot?dxi7k1');
    src: url('#{$fontPath}Icomoon/icomoon.eot?dxi7k1#iefix') format('embedded-opentype'),
    url('#{$fontPath}Icomoon/icomoon.ttf?dxi7k1') format('truetype'),
    url('#{$fontPath}Icomoon/icomoon.woff?dxi7k1') format('woff'),
    url('#{$fontPath}Icomoon/icomoon.svg?dxi7k1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
*/

svg {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

@mixin icomoon() {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    //speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
    @include icomoon();
}