.f-fullImageCta {
    margin-top: 156px;
    margin-bottom: 110px;

    opacity: 0;
    transform: translateY(100px);
    transition: transform 0.35s ease,
        opacity 0.35s ease;

    &.blocInView {
        opacity: 1;
        transform: translateY(0);
    }


    &_link {
        position: absolute;
        bottom: 27px;
        right: 20px;
    }

    &_image {
        width: 100%;

        &--mobile{
            display: none;
        }
    }


    @include m1023 {
        width: 100%;
        height: 192.1875vw;
        max-height: 800px;

        margin-top: 1px;
        margin-bottom: 30px;

        &_image {
            width: 100%;
            height: 100%;
            object-fit: cover;

            &--desktop{
                display: none;
            }

            &--mobile{
                display: block;
            }
        }

        &_link {
            bottom: 20px;
            right: 39px;
        }
    }
}