html {
    --main-color: #{$purple};
    --second-color: #{$green};

    --circle-size: 73%;
    --circle-top: 13.5%;
    --circle-left: 13.5%;
}

.theme,
.subtheme {

    /* COLOR */
    &--pink_little_bubble,
    &--pink_rounded_triangle,
    &--pink_large_bubble {
        --second-color: #{$pink};
    }

    &--green_little_bubble,
    &--green_rounded_triangle,
    &--green_central_circle {
        --second-color: #{$green};
    }

    &--red_large_bubble,
    &--red_central_circle {
        --second-color: #{$red};
    }

    &--yellow_rounded_triangle {
        --second-color: #{$yellow};
    }

    /* POSITION - SIZE */
    &--pink_little_bubble,
    &--green_little_bubble {
        --circle-size: 64%;
        --circle-top: 18.5%;
        --circle-left: 68%;
    }

    &--pink_rounded_triangle,
    &--green_rounded_triangle,
    &--yellow_rounded_triangle {
        --circle-size: 154%;
        --circle-top: -77%;
        --circle-left: 23%;
    }

    &--pink_large_bubble,
    &--red_large_bubble {
        --circle-size: 100%;
        --circle-top: 0%;
        --circle-left: 50%;
    }

    &--green_central_circle,
    &--red_central_circle {
        --circle-size: 73%;
        --circle-top: 13.5%;
        --circle-left: 13.5%;
    }
}