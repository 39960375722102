.f-video {
    background-color: $black;
    margin-top: 105px;
    margin-bottom: 105px;


    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.35s ease, transform 0.35s ease;

    &.blocInView {
        opacity: 1;
        transform: translateY(0);
    }

    &_titre {
        color: $white;
        font-size: 18px;
        font-style: oblique;
        font-weight: 600;
        padding-top: 37px;
        padding-bottom: 32px;
    }

    &_box {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        position: relative;

        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
        }

        &Interface {
            background-color: $black;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            button {
                position: absolute;
                top: 43.8%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 59px;
                height: 59px;
                border-radius: 50%;
                background-color: $purple;
                transition: background-color 0.35s ease;

                &:before {
                    content: "";
                    width: 10px;
                    height: 17px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-25%, -50%);
                    mask-image: url("/assets/img/picto_fleche2.svg");
                    mask-repeat: no-repeat;
                    mask-position: center center;
                    mask-size: contain;
                    background-color: $white;
                    transition: background-color 0.35s ease;
                }

                &:hover {
                    background-color: $white;

                    &:before {
                        background-color: $purple;
                    }
                }
            }
        }
    }

    @include m1023 {
        margin-top: 34px;
        margin-bottom: 32px;

        &_titre {
            font-size: 15px;
            padding-top: 19px;
            padding-bottom: 15px;
            margin-left: $marginNotFull;
            width: 59%;
        }
    }
}