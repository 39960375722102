.loader {
	img {
		display: block;
		margin: 15px auto;
		width: 70px;
		height: auto;
	}
}

.loading-page {
	background-color: $white;
	.loading_headImg {
		height: 400px;
		width: 100%;
		background-image: url('#{$imgPath}bar.gif');
		background-position: top left;
		background-repeat: repeat;
		background-size: 100% 100%;

		@include m768 {
			height: 380px;
		}
	}
	.layout {
		width: 100%;
	}
	.loading_inner {
		padding-top: 50px;
		/* padding: 50px 22px 0 22px;
         background-color: $white;
         @include m768 {
             padding: 50px 50px 0 50px;
         }
         @include m1024 {
             padding: 45px 100px 0 100px;
         }*/
	}
	.loading {
		width: 100%;
		background-image: url('#{$imgPath}bar.gif');
		background-position: top left;
		background-repeat: repeat;
		background-size: 100% 100%;
		.shape {
			background-color: $white;
		}
		.blank_title {
			height: 30px;
			@include m768 {
				height: 52px;
			}
			@include m1024 {
				height: 67px;
			}
		}
		.shape_title {
			height: 43px;
		}
		.blank_intro {
			height: 88px;
			@include m768 {
				height: 108px;
			}
			@include m1024 {
				height: 127px;
			}
		}
		.shape_intro {
			height: 60px;
			@include m768 {
				height: 70px;
			}
			@include m1024 {
				height: 80px;
			}
		}
		.blank_fat {
			height: 165px;
		}
		.shape_fat {
			height: 40px;
		}
		.blank_text {
			height: 300px;
		}
		.shape_text {
			height: 80px;
		}
	}
}
