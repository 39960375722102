.f-keynumbers {
    position: relative;
    margin-top: 102px;
    margin-bottom: 110px;
    cursor: none;

    .withBlocFooter &:last-child{
        margin-bottom: -165px;
    }

    transform: skewY(-10deg) translateY(200px);
    transition: transform 0.5s ease;

    &.blocInView {
        transform: skewY(0deg) translateY(0px);
    }

    &_bloc {
        color: $white;
        padding: 94px 0 126px;
        background-color: var(--main-color);

        &--clone {
            position: absolute;
            top: 0;
            left: 0;
            clip-path: circle(0px at 50% 50%);
            pointer-events: none;
            width: 100%;
            background-color: $white;
            color: var(--second-color);
        }
    }

    &_titre {
        font-weight: 900;
        font-size: 60px;
        font-style: oblique;
        margin-left: 1.8%;
        margin-bottom: 0.5%;
        width: 50%;
        line-height: 1em;

        opacity: 0;
        transform: translateY(100px);
        transition: opacity 0.35s ease 0.5s, transform 0.35s ease 0.5s;

        .blocInView & {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    &_liste {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 1000px;
        margin: 0 auto;
        align-items: center;
    }

    &_item {
        flex: 0 0 32%;
        height: 320px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        opacity: 0;
        transform: translateY(100px);
        transition: opacity 0.35s ease, transform 0.35s ease;
        @include delayTransition(0.15s, 0.6s);

        .blocInView & {
            opacity: 1;
            transform: translateY(0px);
        }

        &_visuel {
            position: relative;
            display: inline-block;
            height: 70px;
            width: 70px;
            mask-repeat: no-repeat;
            mask-position: center center;
            margin-bottom: -10px;

            &--birthday {
                mask-image: url('/assets/img/keynumbers/picto_birthday.svg');
            }

            &--people {
                mask-image: url('/assets/img/keynumbers/picto_people.svg');
            }

            &--flag {
                mask-image: url('/assets/img/keynumbers/picto_flag.svg');
            }

            &--smiley {
                mask-image: url('/assets/img/keynumbers/picto_smiley.svg');
            }

            &--network {
                mask-image: url('/assets/img/keynumbers/picto_course.svg');
            }

            &--buildings {
                mask-image: url('/assets/img/keynumbers/picto_ville.svg');
            }

            &--map {
                mask-image: url('/assets/img/keynumbers/picto_afrique.svg');
            }

            &--front {
                background-color: #fff;
            }

            &--clone {
                background-color: var(--second-color);
            }

            &-- {
                background-color: transparent;
                display: none;
            }
        }

        &_titre {
            font-size: 50px;
            font-weight: 900;
            font-style: oblique;
            white-space: nowrap;
            margin-bottom: 0px;

            em {
                font-size: 40px;
                sup{
                    top: -0.9em;
                }
            }
        }

        &_texte {
            font-size: 22px;
            font-weight: 900;
            font-style: oblique;
            margin: 0 12%;
            margin-top: -5px;
        }

        &_titre2 {
            font-size: 30px;
            font-weight: 900;
            font-style: oblique;
            margin: 0 10%;
            line-height: 1em;
            margin-bottom: 15px;
        }

        &_texte2 {
            font-size: 22px;
            font-weight: 900;
            font-style: oblique;
            margin: 0 10%;
            line-height: 1em;

            ul {
                li {
                    text-align: left;
                    position: relative;
                    padding-left: 28px;

                    &:before {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: $white;
                        left: 8px;
                        top: 7px;
                    }
                }
            }

            &--clone{
                ul{
                    li{
                        &:before{
                            background-color: var(--second-color);
                        }
                    }
                }
            }
        }
    }

    @include m1023 {
        margin-top: 27px;
        margin-bottom: 60px;

        &:last-child{
            margin-bottom: 60px;
        }

        .withBlocFooter &:last-child{
            margin-bottom: 60px;
        }

        &_bloc {
            padding: 39px 0 43px;
        }

        &_titre {
            font-size: 32px;
            text-align: center;
            margin-left: 0%;
            margin-bottom: 11px;
            width: 99%;
        }

        &_liste {
            width: 100%;
            flex-direction: column;
        }

        &_item {
            width: 100vw;
            height: 100vw;
            max-width: 320px;
            max-height: 320px;
            flex: 0 0 100vw;

            &:last-child {
                margin-bottom: 0;
            }

            &_visuel {
                margin-bottom: -14px;
            }

            &_titre {
                margin-bottom: 0px;
            }

            &_texte {
                margin-top: 0;
            }

            &_titre2 {}
        }
    }
}