.detail_offre{
    &:last-child{
        margin-bottom: 173px !important;

        @include m1023{
            margin-bottom: 63px !important;
        }
    }

    &_postulerMobile{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 99px;
    }
}