.f-4blocscontenus {
    margin-top: 82px;
    margin-bottom: 156px;

    &_content {
        display: flex;
        flex-wrap: wrap;
    }

    &_box {
        flex: 0 0 50%;
        height: 0;
        padding-bottom: 50%;
        position: relative;

        &--upper {
            z-index: 1;
        }

        &Absolute {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &--description {
                padding-right: 25%;

                &--reverse {
                    padding-left: 23.5%;
                    padding-right: 0;
                    padding-top: 8%;
                }
            }

            &--message {
                background-color: $black;
                padding-left: 23.6%;
                padding-right: 14%;
                padding-bottom: 4%;
                overflow: hidden;

                &:before {
                    content: "";
                    mask-image: url('/assets/img/picto_blockquote.svg');
                    mask-repeat: no-repeat;
                    mask-position: center center;
                    mask-size: contain;
                    position: absolute;
                    width: 20.23%;
                    height: 12.15%;
                    top: 8.1%;
                    left: -2.7%;
                    background-color: $purple;
                }
            }
        }

        .slick-slider,
        .slick-list,
        .slick-track {
            height: 100%;
        }

        .slick-slider {
            position: absolute;
            width: 100%;
        }

        .slick-slide {
            &>div {
                width: 100%;
                height: 100%;
            }

            .f-4blocscontenus_boxAbsolute--slider {
                position: relative;
            }
        }

        .slick-dots {
            bottom: -45px;

            li {
                margin: 0px 0px;

                button:before {
                    content: "";
                    background-color: $purple;
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                    top: 6px;
                    left: 6px;
                }

                &.slick-active {
                    margin: 0 5px;

                    button:before {
                        width: 20px;
                        height: 20px;
                        top: 0px;
                        left: 0px;
                    }
                }
            }
        }

        opacity: 0;
        transform: translateY(100px);
        transition: opacity 0.35s ease, transform 0.35s ease;
        @include delayTransition(0.35s, 0s, 4);

        .blocInView & {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &_titre {
        font-size: 30px;
        font-weight: 900;
        font-style: oblique;
        color: $purple;
        margin-bottom: 9%;
    }

    &_texte {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.3em;

        &>* {
            margin-top: 26px;
        }

        &>*:nth-child(1) {
            margin-top: 0;
        }
    }

    &_message {
        font-size: 25px;
        font-size: 1.83vw;
        font-weight: 900;
        font-style: oblique;
        color: $white;
        line-height: 1.25em;
        margin-bottom: 23.5%;

        @include m1761 {
            font-size: 32.21px;
        }
    }

    &_author {
        font-size: 16px;
        font-weight: 900;
        color: $white;
    }

    &_slide {
        background-color: $purple;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding-top: 8.2%;
        padding-bottom: 23%;
        position: relative;

        font-size: 0.73206vw;

        @include m1761 {
            font-size: 12.884256px;
        }

        &Titre {
            color: $white;
            font-size: 3.3em;
            font-weight: 900;
            font-style: oblique;
            text-transform: uppercase;
            text-align: center;
        }

        &Date {
            color: var(--second-color);
            font-size: 5.3em;
            font-weight: 900;
            font-style: oblique;
            text-transform: uppercase;
            text-align: center;
        }

        &Location {
            text-align: center;
        }

        &Ville {
            color: $white;
            font-size: 4.5em;
            font-weight: 900;
            font-style: oblique;
            text-transform: uppercase;
        }

        &Pays {
            color: $white;
            font-size: 3.4em;
            font-weight: 500;
            font-style: oblique;
        }

        &:before {
            content: "";
            position: absolute;
            width: 12.4%;
            height: 12.4%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: var(--second-color);
            border-radius: 50%;
        }

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: var(--second-color);
        }
    }

    @include m1023 {
        margin-top: 18px;
        margin-bottom: 0px;

        &_box {
            flex: 0 0 100%;
            height: 0;
            padding-bottom: 100%;

            &--noSquareMobile {
                height: auto;
                padding-bottom: 0;
            }

            &--noSquareMobile &Absolute {
                position: relative;
            }

            &Absolute {
                &--description {
                    @include containerMobile;
                    padding-bottom: 33px;
                    padding-top: 33px;

                    &--reverse {
                        padding-top: 19%;
                    }
                }

                &--message {
                    padding-left: 23.6%;
                    padding-right: 8%;
                    padding-bottom: 4%;

                    &:before {
                        width: 25.8875%;
                        height: 15.55%;
                        top: 11.4%;
                        left: -5.6%;
                    }
                }
            }

            .slick-dots {
                bottom: -33px;

                li {
                    button:before {
                        width: 6px;
                        height: 6px;
                        top: 7px;
                        left: 7px;
                    }

                    &.slick-active {
                        button:before {
                            width: 14px;
                            height: 14px;
                            top: 3px;
                            left: 3px;
                        }
                    }
                }
            }
        }

        &_titre {
            font-size: 22px;
            margin-bottom: 23px;
        }

        &_texte {
            font-size: 16px;
            line-height: 1.4em;

            &>* {
                margin-top: 22px;
            }

            &>*:nth-child(1) {
                margin-top: 0;
            }
        }

        &_message {
            font-size: 17px;
            font-size: 5.3125vw;
            line-height: 1.4em;
            margin-bottom: 14%;
        }

        &_author {
            font-size: 15px;
            font-size: 4.6875vw;
        }

        &_slide {
            padding-top: 8.2%;
            padding-bottom: 25.5%;

            font-size: 3.125vw;

            &Titre {
                font-size: 2.2em;
            }

            &Date {
                font-size: 3.5em;
            }

            &Ville {
                font-size: 2.2em;
            }

            &Pays {
                font-size: 2.2em;
            }

            &:before {
                width: 12.5%;
                height: 12.5%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &:after {
                height: 2px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}