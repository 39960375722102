.f-gouvernance {
  padding-bottom: 149px;

  a {
    display: block;
    width: 100%;
  }

  img {
    height: 100%;
    max-width: none;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }


  &_card {
    display: flex;
    $card: &;
    margin-top: 6vw;

    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 0.35s ease, transform 0.35s ease;
    @include m1023 {
          opacity: 1;
          transform: translateX(0px);
        }
    @include delayTransition(0.35s, 0.35s, 20);

    .blocInView & {
      opacity: 1;
      transform: translateX(0);
    }

    &_imageContainer {
      img{
          height: 100%;
          max-width: none;
          object-fit: cover;
          position: inherit;
          width: 100%;
      }
    }

    @include m480 {
      margin-top: 32px;
      display: flex;
      &:first-child {
        margin-top: 45px;
      }
    }
    .test {
      width: 47.5%;
    }
    >a {
      // height: 0;
      // position: relative;
      // width: 47.5%;
      // padding-bottom: 47.5%;

      #{$card}_content {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -6%;
      }

      #{$card}_title {
        h4 {
          &::after {
            content: "";
            position: absolute;
            bottom: 5px;
            right: 5px;
            border-bottom: 1px solid $purple;
            width: 100%;
            z-index: -1;
            transform: translateX(-100%);
          }
        }
      }
    }

    &_content {
      margin-left: 9.5%;
      font-size: 15px;

      @include m480 {
        font-size: 16px;
      }
    }

    &_title {
      position: relative;

      >h4 {
        color: $purple;
        font-size: 18px;
        font-weight: 900;
        font-style: oblique;
        position: relative;
        @include m480 {
          font-size: 25px;
        }
        >span {
          text-transform: uppercase;
        }
      }

      >p {
        line-height: 1.13;
      }

      >.description{
        margin-top: 20px;
      }
      .linkedin{
        display: flex;
        align-items: center;
        margin: 10px 0;
        &::after{
          content: "";
          width: 38px;
          height: 38px;
          background-image: url("../../assets/img/icon-linkedin-purple.svg");
          background-size: cover;
          @include m479 {
            bottom: -35px;
            width: 30px;
            height: 30px;
          }
        }
        }
    }

    @include m1023 {
      #{$card}_content {
          width: 52.5%;
          margin-right: 9.5%;
          .linkedin{
            justify-content: flex-start;
          }
      }

      &:nth-child(2n) {
        display: flex;
        flex-direction: row-reverse;
        .linkedin{
            justify-content: flex-end;
          }

        #{$card}_content {
          width: 52.5%;
          margin-right: 9.5%;
          text-align: right;

          @include m1024 {
            margin-right: 30px;
          }

          &::after {
            left: 100%;
            right: auto;

            @include m1024 {
              right: -30px;
            }
          }
        }

        #{$card}_title {
          &::after {
            right: 0;
            left: auto;
          }
          h4 {
            &::after {
              right: -5px;
              transform: translateX(100%);
            }
          }
        }
      }
    }

    @include m1024 {
      width: 80%;
      display: flex;
      justify-content: flex-start;
       #{$card}_content{
         width: 52.5%;
         .linkedin{
            justify-content: flex-end;
         }
       }
      &:nth-child(2n + 1) {
        display: flex;
        flex-direction: row-reverse;

        .linkedin{
            justify-content: flex-end;
         }
        #{$card}_content {
          right: 100%;
          left: auto;
          margin-left: 0;
          margin-right: 9.5%;
          text-align: right;
          width: 52.5%;

          &::after {
            right: -24px;
            left: auto;
          }
        }

        #{$card}_title {
          &::after {
            right: 0;
            left: auto;
          }
          h4 {
            &::after {
              right: -5px;
              transform: translateX(100%);
            }
          }
        }
      }
    }

    &--big {
      opacity: 0;
      transform: translateX(-100px);
      transition: opacity 0.35s ease, transform 0.35s ease;
      @include m1023 {
          opacity: 1;
          transform: translateX(0px);
        }

      .blocInView & {
        opacity: 1;
        transform: translateX(0);
      }

      #{$card}_image--big {
        height: 0;
        overflow: hidden;
        position: relative;
        width: 100%;
        padding-bottom: 100%;

        @include m1023 {
          background-color: $purple;
        }

        @include m1024 {
          padding-bottom: calc(100% / 2.48);
          width: calc(100% / 2.48);
          background-color: $purple;
        }

        >img {
          @include m1023 {
            border-radius: 50%;
          }
        }
      }

      #{$card}_content {
        margin: 0;
        width: 46%;
        max-width: none;

        @include m1023 {
          width: 90%;
          // height: 210px;
          // max-height: 210px;
        }

        &::after {
          border: 0;
        }
      }

      #{$card}_title,
      #{$card}_text {
        @include m1024 {
          margin-left: 46px;
          padding: 0;
          max-width: 87%;
        }
      }

      #{$card}_title {
        width: 100%;

        >h4 {
          font-size: 19px;
          margin-top: 16px;

          @include m480 {
            font-size: 25px;
          }

          @include m1024 {
            margin-top: 178px;
          }
        }

        >p {
          line-height: 1.4;
        }

        &::after {
          top: 7px;
          right: 0;
          left: auto;

          @include m479 {
            top: 7px;
            right: 0;
          }
        }
      }

      #{$card}_text {
        margin-top: 16px;
        font-size: 16px;
        line-height: 1.38;

        @include m480 {
          font-size: 20px;
          margin-top: 20px;
          line-height: 1.3;
        }
      }

      @include m1023 {
        display: flex;
        flex-direction: column;
        width: 76.5%;
        margin-top: 75px;
      }

      @include m1024 {
        margin-left: 0;
        display: flex;
      }
    }
  }

  @include m1023 {
    >a {
      display: flex;
      justify-content: flex-end;
    }
  }
}