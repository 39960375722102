.f-blockquotePhoto {
    margin-bottom: 81px;
    margin-top: 99px;
    
    &_content {
        display: flex;
        flex-wrap: wrap;
    }

    &_box {
        flex: 0 0 50%;
        height: 0;
        padding-bottom: 50%;
        position: relative;

        &Absolute {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &--message {
                padding-left: 23.6%;
                padding-right: 14%;
                padding-bottom: 0%;

                &:before {
                    content: "";
                    mask-image: url('/assets/img/picto_blockquote.svg');
                    mask-repeat: no-repeat;
                    mask-position: center center;
                    mask-size: contain;
                    position: absolute;
                    width: 20.23%;
                    height: 12.15%;
                    top: 8.1%;
                    left: -2.7%;
                    background-color: var(--second-color);
                }
            }

            &--photo {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }

        &--noSquare {
            height: auto;
            padding-bottom: 0;
        }

        &--noSquare &Absolute {
            position: relative;
        }
        
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 0.35s ease, transform 0.35s ease;
        @include delayTransition(0.35s, 0s, 2);

        .blocInView & {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &_message {
        font-size: 25px;
        font-size: 1.83vw;
        font-weight: 900;
        font-style: oblique;
        color: $black;
        line-height: 1.25em;
        margin-bottom: 33%;

        @include m1761 {
            font-size: 32.21px;
        }
    }

    &_author {
        font-size: 16px;
        font-weight: 900;
        color: $black;
        margin-bottom: 1.5%;
    }

    &_job {
        font-size: 16px;
        font-weight: 400;
        color: $black;
    }

    @include m1023 {
        margin-bottom: 18px;
        margin-top: 35px;

        &_box {
            flex: 0 0 100%;
            height: 0;
            padding-bottom: 100%;
            margin-top: 12.8%;

            &:first-child {
                margin-top: 0;
            }

            &Absolute {
                &--message {
                    padding-left: 23.6%;
                    padding-right: 8%;
                    padding-bottom: 4%;

                    &:before {
                        width: 25.8875vw;
                        height: 15.55vw;
                        top: 0.7vw;
                        left: -6.3vw;
                    }
                }
            }

            &--noSquare {
                height: auto;
                padding-bottom: 0;
            }

            &--noSquare &Absolute {
                height: auto;
            }
        }

        &_message {
            font-size: 17px;
            font-size: 5.3125vw;
            line-height: 1.4em;
            margin-bottom: 12%;
        }

        &_author {
            font-size: 15px;
            font-size: 4.6875vw;
        }

        &_job {
            font-size: 15px;
            font-size: 4.6875vw;
        }
    }
}