.headerActus {
    display: flex;
    justify-content: space-between;

    &_blocnoir {
        flex: 0 0 34.2%;
        height: 0;
        padding-bottom: 34.2%;
        position: relative;
        align-self: flex-end;

        @include m1024{
            margin-top: 29.45%;
        }

        opacity: 0;
        transform: translateX(100px);
        transition: transform 0.5s ease 0.375s, opacity 0.5s ease 0.375s;

        .blocInView & {
            opacity: 1;
            transform: translateX(0);
        }

        &_content {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
        }

        &_titre {
            @include headerTitre;
            font-size: 45px;
            font-size: 3.294vw;
            margin: 48px 20px 0px 42px;
            opacity: 1;
            transform: none;
            width: 69%;
            line-height: 1em;

            @include m1761 {
                font-size: 58px
            }
        }

        &_scroll {
            @include headerScroll;
            @include headerScrollInterne;
            position: absolute;
            bottom: 18px;
            left: 52px;
            opacity: 1;
            transform: none;

            &:after {
                opacity: 1;
                transform: translateY(0) rotate(90deg);
            }
        }
    }

    &_article {
        flex: 0 0 48.6%;
        margin-right: 8.55%;

        opacity: 0;
        transform: translateY(100px);
        transition: transform 0.5s ease 0.375s, opacity 0.5s ease 0.375s;

        .blocInView & {
            opacity: 1;
            transform: translateY(0);
        }

        &_img {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &_date {
            font-size: 14px;
            color: $purple;
            font-weight: 500;
            margin: 22px 20px 0 0;
        }

        &_title {
            font-size: 28px;
            font-style: oblique;
            font-weight: 900;
            margin-top: 18px;
            line-height: 1em;
            margin-bottom: 70px;
        }
    }

    @include m1023 {
        display: block;

        &_blocnoir {
            align-self: flex-start;
            width: 77vw;
            height: 77vw;
            padding-bottom: 0;
            flex: 0 0 100%;

            &_titre {
                margin-top: 55%;
                line-height: 1em;
                margin-left: 6%;
            }
        }

        &_article {
            margin: 19px 20px 0;
            display: flex;
            flex-direction: column;

            &_title {
                margin-top: 9px;
                margin-bottom: 45px;
            }

            &_img {
                order: 2;
                align-self: flex-end;
                margin-right: -20px;
                width: 76.5vw;
                padding-bottom: 76.5vw;
            }
        }
    }
}