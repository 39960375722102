.headerHome {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    @include headerBack;

    &_square {
        position: absolute;
        width: calc(100vh - 85px);
        height: calc(100vh - 85px);
        top: 62px;
        background-image: radial-gradient(transparent calc(50vh - 45px), #7D146C calc(50vh - 44px));

        opacity: 0;
        transition: opacity 1s ease;

        .blocInView &{
            opacity: 1;
        }
    }

    &_titre {
        @include headerTitre;
        padding: 0 2.5%;
    }

    &_scroll {
        @include headerScroll;
    }

    @include m1023 {
        height: auto;
        display: block;
        overflow: hidden;

        &_square {
            position: relative;
            top: auto;
            width: calc(100vw - 40px);
            height: calc(100vw - 40px);
            max-width: 576px;
            max-height: 576px;
            margin: 0 auto;
            margin-top: 105px;
            background-image: radial-gradient(transparent calc(70.71% - 2px), #7D146C calc(70.71% - 0px));
        }
    }
}