.f-imgLeftRight {
  padding: 30px 0 30px;
  &-container {
    display: flex;
    &_left {
      justify-content: flex-start;
    }
    &_right {
      justify-content: flex-end;
    }
    &-img {
      width: 500px;
      height: 500px;
      object-fit: cover;
    }
  }

  @include m1023 {
    padding: 30px 0 30px;
    &-container {
      &_left {
        justify-content: center;
      }
      &_right {
        justify-content: center;
      }
      &-img {
        width: 85vw;
        height: 85vw;
        max-width: 500px;
        max-height: 500px;
        object-fit: cover;
      }
    }
  }
}
