.headerType2 {
    position: relative;
    margin-bottom: 93px;

    @include headerBack;

    &_box {
        padding-top: 5.65%;
        @include headerCases;
    }

    &_categorie {
        display: none;
    }

    &_backlink {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 21px 68px;
        letter-spacing: -0.03em;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            width: 17px;
            height: 13px;
            left: -25px;
            top: 1px;
            mask-image: url('/assets/img/picto_fleche_small.svg');
            mask-position: center center;
            mask-size: contain;
            mask-repeat: no-repeat;
            background-color: $black;
        }
    }

    &_date {
        font-size: 14px;
        color: $pink;
        font-weight: 500;
        margin: 39px 20px 0px 48px;
    }

    &_titre {
        @include headerTitre;
        font-size: 30px;
        font-size: 2.196193vw;
        margin: 7px 20px 0px 38px;
        opacity: 1;
        transform: none;
        width: auto;
        line-height: 1.025em;

        @include m1761 {
            font-size: 38.67px
        }
    }

    &_scroll {
        @include headerScroll;
        @include headerScrollInterne;
        position: absolute;
        bottom: 18px;
        left: 52px;
        opacity: 1;
        transform: none;

        &:after {
            opacity: 1;
            transform: translateY(0) rotate(90deg);
        }
    }

    &_thumbnail {
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -1;
        position: relative;
    }

    @include m1023 {
        margin-bottom: 0;
        &_box {
            padding-top: 0;
            flex-direction: column;
            align-items: flex-start;

            &_square1 {
                width: 100%;

                &_content {
                    background-color: $purple;
                }
            }

            &_square2 {
                width: 100%;

                &_content {
                    background: transparent;
                }
            }
        }

        &_infos {
            background-color: $black;
            width: 77vw;
            height: 77.1vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        &_categorie {
            display: block;
            font-size: 28px;
            font-size: 8.75vw;
            font-weight: 900;
            font-style: oblique;
            color: $white;
            margin-bottom: 20.6%;
            margin-left: 8%;
            margin-right: 8%;
        }

        &_backlink {
            color: $white;
            font-weight: 600;
            margin: 0 0 5.4% calc(8% + 26px);
            letter-spacing: 0;

            &:before {
                background-color: $white;
            }
        }

        &_date {
            font-size: 14px;
            margin: 0;
            padding: 22px 20px 0px 20px;
        }

        &_titre {
            font-size: 28px;
            font-size: 8.75vw;
            line-height: 1.15em;
            margin: 2.3% 4.9% 0 4.9%;
            padding-bottom: 12%;
        }
    }
}