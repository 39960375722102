.f-text2cols {
  margin-top: 61px;
  margin-bottom: 30px;

  &.detail_offre {
    margin-bottom: 25px;
  }

  &_secondtitre {
    font-size: 30px;
    font-weight: 900;
    line-height: 1em;
    font-style: oblique;
    margin-bottom: 19px;
    color: $purple;

    opacity: 0;
    transform: translateY(100px);
    transition: transform 0.35s ease,
      opacity 0.35s ease;

    .blocInView & {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.detail_offre &_secondtitre {
    margin-bottom: 34px;
  }

  &-container {
    display: flex;
    justify-content: space-between;

    &_text {
      font-size: 20px;
      line-height: 1.3em;
      margin-bottom: 0px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &>div {
        width: 45.2%;

        opacity: 0;
        transform: translateY(100px);
        transition: transform 0.35s ease,
          opacity 0.35s ease;
        @include delayTransition(0.35s, 0.35s, 2);

        .blocInView & {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &_button {
    font-size: 14px;
    font-weight: 600;
    color: $purple;
    text-transform: uppercase;
    margin-top: 17px;

    &:after {
      content: "";
      width: 17px;
      height: 13px;
      background-color: $purple;
      mask-image: url("/assets/img/picto_fleche.svg");
      mask-repeat: no-repeat;
      mask-position: center center;
      mask-size: contain;
      display: inline-block;
      margin-left: 12px;
      transform: translateY(1px);
    }
  }

  @include m1023 {
    margin-top: 25px;
    margin-bottom: 30px;

    &.detail_offre {
      margin-top: 21px;
    }

    &_secondtitre {
      font-size: 22px;
      margin-bottom: 27px;
    }

    &.detail_offre &_secondtitre {
      margin-bottom: 29px;
    }

    &-container {
      &_text {
        font-size: 16px;
        line-height: 1.38em;
        margin-bottom: 0px;
        column-gap: initial;
        column-count: initial;
        flex-direction: column;

        div {
          width: 100%;
        }
      }
    }
  }
}