.f-5cases {
    height: 50vw;
    display: flex;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0%;
        transition: width 0.5s ease;
        background-color: $black;
    }

    &.blocInView {
        &:before {
            width: 100%;
        }
    }

    &_case {
        flex: 0 0 50%;
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        position: relative;

        &--black {
            color: $white;
        }

        &--full {
            align-items: center;
            justify-content: center;

            &Container {
                width: 69%;
                padding-left: 11.5%;
                margin-bottom: 9.9%;

                &>* {
                    opacity: 0;
                    transform: translateX(-50px);
                    transition: opacity 0.35s ease, transform 0.35s ease;
                    @include delayTransition(0.175s, 0s, 5);

                    .blocInView & {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
            }
        }
    }

    &_subcase {
        flex: 0 0 50%;
        width: 50%;
        height: 50%;
        border-right: solid 1px $black;
        border-top: solid 1px $black;
        border-bottom: solid 1px $black;
        font-size: 35px;
        font-size: 2.5622vw;
        text-transform: uppercase;
        font-weight: 900;
        font-style: oblique;
        background-color: $white;

        &:nth-child(2),
        &:nth-child(4) {
            border-right: none;
        }

        &:nth-child(3),
        &:nth-child(4) {
            border-top: none;
        }

        &_link {
            line-height: 1em;
            width: 100%;
            height: 100%;
            padding: 3px 18% 0 12.2%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            position: relative;
            color: $black;
            transition: color 0.35s ease;

            &Text {
                position: relative;
                pointer-events: none;
            }

            &:after {
                display: block;
                content: "";
                width: 36px;
                height: 24px;
                margin-top: 14px;
                position: relative;
                pointer-events: none;
                mask-image: url("/assets/img/picto_fleche.svg");
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center center;
                background-color: $black;
            }
        }

        &_animation {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            transition: opacity 0.35s ease;
            overflow: hidden;
            background-color: var(--second-color);

            &_circleBox {
                position: absolute;
                pointer-events: none;
                width: var(--circle-size);
                height: var(--circle-size);
                top: var(--circle-top);
                left: var(--circle-left);
            }

            &_circle {
                border-radius: 50%;
                transform: scale(0);
                transition: transform 0.35s ease;
                width: 100%;
                height: 100%;
                display: block;
                pointer-events: none;
                background-color: var(--main-color);
            }
        }

        &--type1 &_animation {
            right: -1px;
            bottom: -1px;
        }

        &--type2 &_animation {
            left: -1px;
            bottom: -1px;
        }

        &--type3 &_animation {
            right: -1px;
            top: -1px;
        }

        &--type4 &_animation {
            left: -1px;
            top: -1px;
        }

        &_link:hover {
            color: $white;

            &:after {
                background-color: $white;
            }
        }

        &_link:hover &_animation {
            opacity: 1;

            &_circle {
                transform: scale(1);
            }
        }

        opacity: 0;
        transition: opacity 0.25s ease;
        $delay : 0.125s;
        $start : 0.5s;

        &:nth-child(1) {
            transition-delay: $start;
        }

        &:nth-child(2) {
            transition-delay: $start + $delay;
        }

        &:nth-child(4) {
            transition-delay: $start + $delay * 2;
        }

        &:nth-child(3) {
            transition-delay: $start + $delay * 3;
        }

        .blocInView & {
            opacity: 1;
        }
    }

    &_titre {
        font-size: 160px;
        font-size: 11.713vw;
        font-weight: 900;
        font-style: oblique;
        margin-bottom: 0.1em;
    }

    &_subtitre {
        font-size: 65px;
        font-size: 4.7584vw;
        font-weight: 900;
        font-style: oblique;
        line-height: 1em;
        margin-bottom: 0.4em;
    }

    &_texte {
        font-size: 30px;
        font-size: 2.1962vw;
        font-weight: 600;
        font-style: oblique;
        line-height: 1.275em;
    }

    @include m1023 {
        flex-direction: column;
        height: auto;

        &_case {
            flex: 0 0 100vw;
            width: 100%;
            display: block;

            &--full {
                &Container {
                    width: 100%;
                    padding: 0 $marginNotFull;
                    margin-bottom: 2%;
                    padding-top: 7%;
                }
            }

            .f-5cases &.slick-slider {
                flex: 0 0 84vw;
            }
        }

        &_titre {
            font-size: 21.875vw;
            margin-bottom: 0.1em;
        }

        &_subtitre {
            font-size: 8.75vw;
            margin-bottom: 0.75em;
            width: 85vw;
        }

        &_texte {
            font-size: 6.5625vw;
            line-height: 1.275em;
            width: 85vw;
        }

        &_subcase {
            border: none;
            font-size: 27px;
            font-size: 8.4375vw;

            &_link {
                color: $white;
                width: 84vw;
                height: 84vw;

                &:after {
                    background-color: $white;
                    width: 27px;
                    height: 17px;
                }
            }

            &_link &_animation {
                opacity: 1;

                &_circle {
                    transform: scale(1);
                }
            }
        }

        .slick-dots {
            bottom: -24px;
            pointer-events: none;

            li {
                margin: 0px 0px;
                width: 14px;
                height: 14px;

                button:before {
                    content: "";
                    background-color: $purple;
                    border-radius: 50%;
                    width: 6px;
                    height: 6px;
                    top: 4px;
                    left: 4px;
                }

                &.slick-active {
                    margin: 0 5px;

                    button:before {
                        width: 12px;
                        height: 12px;
                        top: 1px;
                        left: 1px;
                    }
                }
            }
        }
    }

    &Line {
        display: flex;
        width: 100%;
        position: relative;
        margin-top: 165px;
        border-bottom: 1px solid $black;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 0%;
            transition: width 0.5s ease;
            background-color: $black;
            z-index: -1;
        }

        &.blocInView {
            &:before {
                width: 100%;
            }
        }

        &_bloc, &_bloc__smaller {
            height: 0;
            padding-bottom: 100%;
            position: relative;
        }

        .f-5cases_subcase_link {
            position: absolute;
            top: 0;
            left: 0;
            padding: 7px 18% 0px 12.2%;

            &:after {
                width: 46px;
                height: 30px;
                margin-top: 22px;
            }
        }

        &_case {
            width: 100%;
            display: flex;
        }

        &--display_two {
            .f-5cases_subcase {
                flex: 0 0 50%;
                width: 50%;
                font-size: 59px;
                font-size: 4.31918vw;
            }
        }

        &--display_three {
            .f-5cases_subcase {
                flex: 0 0 33.3333333333333333333%;
                width: 33.3333333333333333333%;
                font-size: 46px;
                font-size: 3.367496vw;
            }
        }

        .f-5cases_subcase {
            height: auto;
            border-right: solid 1px $black;
            border-top: solid 1px $black;
            border-bottom: none;

            &:last-child {
                border-right: none;
            }

            &--type1 .f-5cases_subcase_animation {
                right: -1px;
                bottom: -1px;
                top: 0;
            }

            &--type2 .f-5cases_subcase_animation {
                left: -1px;
                right: -1px;
                bottom: -1px;
                top: 0;
            }

            &:last-child .f-5cases_subcase_animation {
                left: -1px;
                bottom: 0px;
                top: 0;
            }
        }

        @include m1023 {
            margin-top: 53px;

            .f-5cases_subcase {
                flex: 0 0 50%;
                width: 50%;
                border-top: none;
                font-size: 16px;
                font-size: 5vw;
                display: block !important;

                // &:nth-child(3) {
                //     display: none;
                // }

                &--type2 .f-5cases_subcase_animation {
                    left: 0px;
                }
            }

            .f-5casesLine_bloc {
                height: 50vw;
                width: 50vw;
                &__smaller {
                    height: 45vw;
                    width: 45vw;
                }
            }


            .f-5cases_subcase_link {
                width: 100%;
                height: 100%;

                &:after {
                    width: 15px;
                    height: 10px;
                    margin-top: 8px;
                }
            }

            .slick-dots {
                z-index: 1;
                pointer-events: none;
                display: none !important;
            }
        }
    }
}