body {
    font-family: $fontText;
}

.app {
    position: relative;
    max-width: 100vw;
    overflow-x: hidden;
}

$marginNotFull : 20px;

.container {
    box-sizing: border-box;
    width: calc(100% - 160px);
    max-width: 1600px;
    min-width: 980px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @include m1023 {
        width: auto;
        max-width: none;
        min-width: 0px;

        &--notfull {
            margin-left: $marginNotFull;
            margin-right: $marginNotFull;
        }
    }

    .subcontainer {
        margin-left: 8.54125%;
        margin-right: 8.54125%;
        position: relative;

        @include m1023 {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@mixin containerMobile {
    @include m1023 {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.onlyMobile{
    display: none;

    @include m1023{
        display: block;
    }
}