.c-button {
    &--type1 {
        background-color: $purple;
        height: 58px;
        max-width: 58px;
        display: inline-flex;
        border-radius: 30px;
        align-items: center;
        color: $white;
        font-size: 20px;
        font-weight: 700;
        font-style: oblique;
        text-transform: uppercase;
        transition: max-width 0.35s ease;
        justify-content: flex-end;
        white-space: nowrap;
        position: relative;
        padding-right: 48px;

        span {
            display: inline-block;
            padding-left: 25px;
            opacity: 0;
            transition: opacity 0.25s ease;
        }

        &:after,
        &:before {
            content: "";
            background-color: $white;
            position: absolute;
        }

        &:before {
            width: 18px;
            height: 2px;
            right: 20px;
            top: 28px;
        }

        &:after {
            height: 17px;
            width: 2px;
            right: 28px;
            top: 21px;
        }

        &:hover {
            max-width: 300px;

            span {
                opacity: 1;
            }
        }

        @include m1023 {
            font-size: 17px;
        }
    }

    &--download {
        position: relative;
        padding-left: 23px;

        &:before {
            content: "";
            width: 13px;
            height: 20px;
            background: transparent url('/assets/img/picto_telecharger.svg') no-repeat center center;
            background-size: contain;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &--read {
        position: relative;
        padding-left: 0px;

        &:before {
            content: "";
            width: 13px;
            height: 20px;
            background-color: $purple;
            mask-image: url("/assets/img/picto_fleche.svg");
            mask-repeat: no-repeat;
            mask-position: center center;
            mask-size: contain;
            position: absolute;
            left: calc(100% + 10px);
            top: -2px;
        }
    }

    &--btnPlus {
        position: relative;
        font-size: 20px;
        font-weight: 700;
        font-style: oblique;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: $white;

        &:after {
            content: "";
            width: 59px;
            height: 59px;
            background: $white url('/assets/img/picto_plus.svg') no-repeat center center;
            border-radius: 50%;
            display: block;
            margin-left: 10px;
            flex: 0 0 59px;
        }
    }

    &--btnArrow {
        position: relative;
        font-size: 20px;
        font-weight: 700;
        font-style: oblique;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: $purple;
        text-align: right;
        padding-right: 69px;
        transition: color 0.35s ease;

        span {
            position: relative;
            z-index: 1;
            white-space: nowrap;
        }

        &:before{
            content: "";
            background-color: $white;
            mask-image: url('/assets/img/arrowCtaCarresWhite.svg');
            mask-repeat: no-repeat;
            mask-position: 17px center;
            mask-size: 25px;
            width: 59px;
            height: 59px;
            position: absolute;
            right: 0;
            z-index: 1;
        }

        &:after {
            content: "";
            width: 59px;
            height: 59px;
            background-color: $purple;
            border-radius: 30px;
            display: block;
            flex: 0 0 59px;
            position: absolute;
            right: 0;
            transition: width 0.35s ease;
        }

        &:hover {
            color: $white;

            &:after {
                width: calc(100% + 30px);
            }
        }

        &--plus {
            &:before {
                mask-image: url('/assets/img/picto_plus.svg');
                mask-size: 18px;
                mask-position: 20px center;
            }
        }

        &--white {
            color: $white;
            &:before{
                background-color: $black;
            }
            &:after {
                background-color: $white;
            }
            &:hover {
                color: $black;
            }
        }

        &--whitepurple {
            color: $white;
            &:before{
                background-color: $purple;
            }

            &:after {
                background-color: $white;
            }
            &:hover {
                color: $purple;
            }
        }
    }
}