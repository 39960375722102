.t404 {
  &_container {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    @include m1023 {
      height: 568px;
    }
  }
  &_bg_purple {
    position: absolute;
    top: 50%;
    left: 80%;
    background-color: #7D146C;
    transform: translate(-55%, -50%) rotate(-8deg);
    width: 70%;
    height: 200vh;
    @include m1023 {
      top: 48.5%;
      left: 50%;
      width: 150%;
      height: 64.5%;
    }
  }
  &_bg_black {
    position: absolute;
    top: 100%;
    left: 25%;
    background-color: $black;
    transform: translate(-60%, -53%) rotate(-9deg);
    width: 100%;
    height: 75%;
    @include m1023 {
      left: 50%;
      width: 150%;
    }
  }
  &_circle {
    position: absolute;
    top: 114%;
    left: 22%;
    background-color: $white;
    border-radius: 100%;
    transform: translate(-50%, -50%) rotate(-9deg);
    width: 40%;
    height: 40%;
    padding-bottom: 40%;
    @include m1023{
      left: 50%;
      width: 84%;
      height: 45%;
      padding-bottom: 45%;
    }
  }
  &_main {
    position: absolute;
    transform: translate(118%, 68%);
    width: 50%;
    color: $white;
    @include m1023 {
      transform: translate(8%, 93.5%);
      width: 100%;
    }
    &_title {
      width: 45%;
      max-width: 120px;
      font-size: 64px;
      font-style: italic;
      font-weight: 700;
      margin-bottom: 28px;
      line-height: 1;
      @include m1023 {
        width: 35%;
        font-size: 26px;
      }
    }
    &_text {
      width: 55%;
      max-width: 320px;
      font-size: 32px;
      font-weight: 500;
      font-style: italic;
      @include m1023 {
        width: 70%;
        max-width: 230px;
        font-size: 20px;
        font-weight: 600;
      }
    }
    &_link {
      position: relative;
      margin-top: 85px;
      text-transform: uppercase;
      font-size: 14px;
      padding-left: 25px;
      font-weight: 500;
      @include m1023 {
        margin-top: 58px;
        padding-left: 23px;
      }
      &::before {
        content: "";
        width: 16px;
        height: 16px;
        background: transparent url('/assets/img/picto_arrow_left.svg') no-repeat center center;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        @include m1023 {
          left: -2px;
        }
      }
    }
  }
}