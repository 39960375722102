.f-actus2cols {
    margin-top: 100px;
    margin-bottom: 97px;

    &_content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &_col {
        &:nth-child(1) {
            flex: 0 0 38.1%;
            padding-top: 3px;
        }

        &:nth-child(2) {
            flex: 0 0 48.7%;
        }
    }

    &_article {
        display: flex;
        width: 100%;

        opacity: 0;
        transform: translateY(50px);
        transition: opacity 0.35s ease, transform 0.35s ease;
        @include delayTransition(0.35s, 0.35s);

        &--main:nth-child(1) {
            transition-delay: 0s;
        }

        .blocInView & {
            opacity: 1;
            transform: translateY(0);
        }

        &_subarticle {
            flex: 0 0 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: right;
            margin: -6px 5.7% 0 6.1%;
        }

        &:nth-child(2n) {
            justify-content: flex-end;
        }

        &:nth-child(2n+1) &_subarticle {
            order: 2;
            text-align: left;
        }

        &_date {
            color: $purple;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 3px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                height: 1px;
                width: 100%;
                background-color: $purple;
                left: calc(100% + 4px);
                right: auto;
                top: 11px;
            }
        }

        &:nth-child(2n+1) &_date {
            &:before {
                left: auto;
                right: calc(100% + 4px);
            }
        }

        &_titre {
            font-size: 18px;
            font-weight: 600;
            font-style: oblique;
            line-height: 1.3em;
        }

        &_visuel {
            flex: 0 0 36.6%;

            &Container {
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                overflow: hidden;
            }

            img {
                width: 100%;
                max-width: none;
                height: 100%;
                position: absolute;
                object-fit: cover;
                z-index: 1;
            }
        }

        &--main {
            flex-direction: column;
        }

        &--main:nth-child(n) &_subarticle {
            order: initial;
            margin: 0 0;
            flex: 0 0;
        }

        &--main &_date {
            margin-bottom: 15px;

            &:before {
                display: none;
            }
        }

        &--main &_titre {
            font-size: 35px;
            font-weight: 900;
            flex: 0 0 41%;
            margin-bottom: 49px;
            line-height: 1em;
        }

        &--main &_visuel {
            flex: 0 0;
        }
    }

    &_bouton {
        margin: 94px 0 0;
        text-align: right;
    }

    @include m1023 {
        margin-top: 47px;
        margin-bottom: 53px;

        &_content {
            flex-direction: column;
            align-items: initial;
        }

        &_article {
            margin-bottom: 21px;

            &_subarticle {
                max-width: 40%;
                margin-top: 0;
            }

            &_date {
                margin-bottom: 4px;
            }

            &_titre {
                font-size: 15px;
                line-height: 1.15em;
            }

            &_visuel {
                flex: 0 0 46.6%;
            }

            &--main {
                margin-bottom: 40px;
            }

            &--main:nth-child(n) &_subarticle {
                width: auto;
                max-width: none;
                margin: 0 $marginNotFull;
            }

            &--main &_date {
                margin-bottom: 9px;
            }

            &--main &_titre {
                font-size: 28px;
                width: 230px;
                margin-bottom: 17px;
            }


            &--main &_visuel {
                width: 76.7%;
                margin-left: auto;
            }
        }

        &_bouton {
            margin: 18px 24px 0 0;
        }
    }
}