.link-hover {
    position: relative;

    &_picto {
        position: absolute;
        width: 59px;
        height: 59px;
        pointer-events: none;
        border-radius: 30px;

        opacity: 0;
        transform: scale(0) translate(-75%, -75%);
        transition: opacity 0.15s ease, transform 0.15s ease;

        background-color: $purple;
        z-index: 5;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $white;
            mask-repeat: no-repeat;
            mask-position: center center;
        }

        &--plus {
            &:before {
                mask-image: url("/assets/img/picto_plus.svg");
                mask-size: 35%;
            }
        }

        &--download {
            &:before {
                mask-image: url("/assets/img/picto_telecharger.svg");
                mask-size: 25%;
            }
        }

        &--linkedin {
            &:before{
                mask-image: url("/assets/img/icon-linkedin-purple.svg");
                mask-size: 70%;
            }
        }
    }

    &:hover &_picto {
        @include m1024 {
            opacity: 1;
            transform: scale(1) translate(-75%, -75%);
        }
    }

    &--withImage {
        @include m1024 {
            img {
                transform: scale(1);
                transition: transform 0.35s ease;
            }

            &:hover img {
                transform: scale(1.2);
            }
        }
    }
}