.f-image {
    margin-top: 77px;
    margin-bottom: 77px;

    opacity: 0;
    transform: translateY(100px);
    transition: transform 0.35s ease,
        opacity 0.35s ease;

    &.blocInView {
        opacity: 1;
        transform: translateY(0);
    }

    img {
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    &Container {
        margin-left: auto;
        margin-right: auto;

        &--medium {
            width: 79.4%;
        }

        &--small {
            width: 30.8%;
            height: 0;
            padding-bottom: 30.8%;
            position: relative;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    @include m1023 {
        margin-top: 52px;
        margin-bottom: 52px;

        &Container {

            &--medium,
            &--small {
                width: 47.1875vw;
                height: 47.1875vw;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &--small {
                padding-left: 0;
                padding-right: 0;
            }

            &--left {
                margin-right: auto;
                margin-left: 0;
            }

            &--right {
                margin-left: auto;
                margin-right: 0;
            }

            &--center_with_marge {
                margin-right: auto;
                margin-left: auto;
                padding-right: 20px;
                padding-left: 20px;
            }

            &--center_without_marge {
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    &--only_top {
        margin-bottom: 0;

        &+.flexible {
            margin-top: 0 !important;
        }
    }

    &--only_bottom {
        margin-top: 0;
    }
}

.flexible--only_bottom {
    margin-bottom: 0 !important;
}