@mixin headerBack {
    &_back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@mixin headerInterne {
    @include m1023 {
        display: flex;
        flex-direction: column;

        &_back {
            position: relative;
            width: 100vw;
            height: 100vw;
            order: 2;
            opacity: 0;
            transform: translateX(100px);
            transition: transform 0.5s ease 0.375s, opacity 0.5s ease 0.375s;

            .blocInView & {
                opacity: 1;
                transform: translateX(0);
            }
        }

        &--noMediaMobile &_back {
            display: none;
        }

        &_box {
            width: 100%;
        }
    }
}

@mixin headerTitre {
    color: $white;
    font-size: 50px;
    font-size: 6.4935vh;
    font-weight: 900;
    font-style: oblique;
    position: relative;
    width: calc(100vh - 42px);
    margin-bottom: 28px;
    line-height: 0.9em;
    padding: 0 1.5%;

    opacity: 0;
    transform: translateY(-100px);
    transition: transform 1s ease, opacity 1s ease;

    .blocInView & {
        opacity: 1;
        transform: translateY(0);
    }

    @include m1023 {
        width: calc(100vw - 31px);
        max-width: 489px;
        margin: 0 auto;
        font-size: 30px;
        font-size: 9.375vw;
        margin-top: 9px;
        line-height: 1em;

        @include m576 {
            font-size: 54px;
        }
    }
}

@mixin headerCases {
    display: flex;
    align-items: flex-end;

    &_square {
        &1 {
            flex: 0 0 34.15%;

            &_content {
                background-color: $black;
            }

            opacity: 0;
            transform: translateY(-100px);
            transition: transform 0.5s ease,
            opacity 0.5s ease;

            .blocInView & {
                opacity: 1;
                transform: translateY(0);
            }
        }

        &2 {
            flex: 0 0 57.35%;

            &_content {
                background-image: radial-gradient(transparent calc(70.71% - 1px), #7D146C calc(70.71% - 0px));
            }

            &--noRound &_content {
                background: $purple;
                padding: 56px 58px;
            }

            opacity: 0;
            transform: translateX(100px);
            transition: transform 0.5s ease 0.375s,
            opacity 0.5s ease 0.375s;

            .blocInView & {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

    &_relative {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        position: relative;
    }

    &_absolute {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    @include m1023 {
        &_relative--notSquareMobile {
            height: auto;
            padding-bottom: 0;
        }

        &_relative--notSquareAtAllMobile {
            display: none;
        }

        &_relative--notSquareMobile &_absolute {
            position: relative;
        }

        &_square2 {
            &--noRound &_content {
                padding: 0px;
            }
        }
    }
}

@mixin headerCasesInterne1 {
    @include m1023 {
        &_box {
            &_square {
                &1 {
                    flex: 0 0 77%;
                }

                &2 {
                    display: block;
                    position: absolute;
                    top: 100%;
                    z-index: 1;
                    width: 100vw;
                }
            }
        }

        &--noMediaMobile &_box {
            &_square {
                &2 {
                    display: none;
                }
            }
        }
    }
}

@mixin headerScroll {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 41px;

    opacity: 0;
    transform: translateY(-100px);
    transition: transform 1s ease 0.25s, opacity 1s ease 0.25s;

    &:after {
        content: "";
        width: 24px;
        height: 16px;
        display: block;
        margin: 0 auto;
        background-color: $white;
        mask-image: url("/assets/img/picto_fleche.svg");
        mask-size: cover;
        mask-repeat: no-repeat;
        mask-position: center center;
        opacity: 0;
        transform: translateY(200px) rotate(90deg);
        transition: transform 1s ease 0.25s, opacity 1s ease 0.25s;
        margin-top: 12px;
    }

    .blocInView & {
        opacity: 1;
        transform: translateY(0);

        &:after {
            opacity: 1;
            transform: translateY(0) rotate(90deg);
        }
    }

    @include m1023 {
        margin-left: auto;
        margin-right: 18px;
        margin-top: -30px;
        display: block;
        text-indent: -99999px;
        margin-bottom: 30px;
    }
}

@mixin headerScrollInterne {
    @include m1023 {
        display: none;
    }
}