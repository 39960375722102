// Variables declaration

@mixin button($backgroundColor: $black, $backgroundColorSecond: transparent) {
    background-color: $backgroundColor;
    border: 2px solid $backgroundColor;
    outline: 0 !important;

    @include m1024 {

        &:hover,
        &:focus-within {
            color: $backgroundColor;
            background-color: $backgroundColorSecond;
        }
    }
}

//FONTS
$fontTitle:'Futura PT Multilingual W01';
$fontText: 'Futura PT Multilingual W01';

//IMAGES
$imgPath: '../../assets/img/';
// Use : background-image: url('#{$imgPath}menu-wave-mini.svg');

//COLORS
$black: #000000;
$white: #ffffff;
$gray: #D3D3D3;
$purple: #7D146C;
$red: #F3251C;
$green: #03BD80;
$pink : #FF7FC7;
$yellow: #ECCA00;