.f-titreModule {
  margin-bottom: 37px;
  margin-top: 114px;

  &:first-child {
    margin-top: 0;
  }

  opacity: 0;
  transform: translateY(100px);
  transition: transform 0.35s ease,
  opacity 0.35s ease;

  &.blocInView {
    opacity: 1;
    transform: translateY(0);
  }

  &_maintitre {
    font-size: 50px;
    font-weight: 900;
    line-height: 1em;
    font-style: oblique;
    position: relative;

    &-cube {
      position: absolute;
      width: 72px;
      height: 72px;
      background-color: var(--second-color);
      top: -10px;
      left: -10.3%;
      overflow: hidden;

      &-inner {
        position: absolute;
        width: var(--circle-size);
        height: var(--circle-size);
        background-color: var(--main-color);
        top: var(--circle-top);
        left: var(--circle-left);
        border-radius: 50%;
      }
    }

    &-span {
      max-width: 72%;
      display: block;
      line-height: 1.1em;
    }
  }

  @include m1023 {
    margin-bottom: 25px;
    margin-top: 30px;
    min-height: 75px;

    &_maintitre {
      font-size: 32px;
      padding: 19px 0 0 75px;
      line-height: 0.875em;

      &-cube {
        top: 0px;
        left: -20px;
        width: 75px;
        height: 75px;
      }

      &-span {
        max-width: 100%;
        display: block;
        line-height: inherit;
      }
    }
  }
}