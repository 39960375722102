.f-communiques {
    margin-top: 100px;
    margin-bottom: 172px;

    &.standAlone {
        margin-top: 68px;
        margin-bottom: 114px;
    }

    &_maintitre {
        font-size: 35px;
        font-weight: 900;
        line-height: 1em;
        text-transform: uppercase;
        font-style: oblique;
        width: 310px;
        margin-bottom: 95px;

        opacity: 0;
        transform: translateY(50px);
        transition: opacity 0.35s ease, transform 0.35s ease;

        .blocInView & {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &_content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        flex-wrap: wrap;
    }

    &.standAlone &_content {
        margin-bottom: 11px;
    }

    &_item {
        flex: 0 0 27.8%;
        margin-bottom: 28px;

        opacity: 0;
        transform: translateY(50px);
        transition: opacity 0.35s ease, transform 0.35s ease;
        @include delayTransition(0.175s, 0s, 15);

        .blocInView & {
            opacity: 1;
            transform: translateY(0);
        }

        &:last-child:nth-child(3n+2) {
            margin-left: 8.3%;
            margin-right: auto;
        }

        &_container {
            border-bottom: solid 1px $black;
            padding-bottom: 22px;
        }
    }

    &.standAlone &_item {
        padding-bottom: 32px;
        margin-bottom: 43px;
    }

    &_date {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 13px;
    }

    &_titre {
        font-size: 23px;
        font-weight: 600;
        line-height: 1.3em;
        margin-bottom: 18px;
    }

    &_lien {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        color: $purple;
    }

    &_bouton {
        text-align: right;
    }

    @include m1023 {
        margin-top: 85px;
        margin-bottom: 51px;

        &.standAlone {
            margin-top: 34px;
            margin-bottom: 30px;
        }

        &_maintitre {
            font-size: 28px;
            width: 250px;
            margin-bottom: 30px;
        }

        &_content {
            flex-direction: column;
            padding: 0 2px;
            margin-bottom: 24px;
        }

        &.standAlone &_content{
            margin-bottom: 24px;
        }

        &_item {
            padding-bottom: 20px;
            margin-bottom: 23px;

            &:last-child {
                margin-bottom: 0;

                &:nth-child(3n+2) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }

        &.standAlone &_item {
            padding-bottom: 20px;
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &_date {
            font-size: 12px;
            margin-bottom: 6px;
        }

        &_titre {
            font-size: 18px;
            margin-bottom: 16px;
            line-height: 1.2em;
        }

        &_bouton {
            margin-right: 3px;
        }
    }
}