
// Ajout JC
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }
  
  img{
    display: block;
    max-width: 100%;
    height: auto;
  }
  
// Ajout CB
html{
font-size: 10px;
scroll-behavior: smooth; //smooth scrolling
}
body{
font-size: 100%;
}

svg{
fill:currentColor;
}

