.f-text {
    .container {
        text-align: center;
        > *:not(blockquote) {
            text-align: left;
        }
    }
    blockquote, blockquote * {
        display: inline-block;
        text-align: center;
    }
    &--imgRight {
        .f-textFlex {
            flex-direction: row-reverse;
        }
    }
    &Flex {
        @include flex(space-between, center);
        margin-left: -10px;
        margin-right: -10px;
        @include m991 {
            display: block;
        }
        > * {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    &-img {
        max-width: 480px;
        width: calc(100% - 20px);
        @include m991 {
            max-width: 999px;
            margin-bottom: 30px;
        }
        img {
            width: 100%;
        }
    }
    &-ctn {
        max-width: 380px;
        @include m991 {
            max-width: 999px;
        }
    }
}