.f-slider3options {
  &-cta {
    margin-top: 90px;

    @include m1023 {
      margin-top: 60px;
      margin-bottom: 60px;
      padding-right: 20px;
      display: flex;
      justify-content: flex-end;
    }
  }

  &-slider {
    .slick-dots {
      bottom: -55px;

      li {
        margin: 0;
      }
    }

    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: $purple;
      font-size: 20px;
    }

    .slick-dots li button:before {
      font-size: 8px;
    }
  }

  &-container {
    display: flex !important;

    &-imgAlone {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include m1024 {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &-imgText {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      @include m1024 {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    @include m1024 {
      &-Container {
        width: 50%;
        height: 0;
        padding-bottom: 50%;
        position: relative;

        &Absolute {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
        }
      }

      &-ContainerDouble {
        width: 100%;
        height: 0;
        padding-bottom: 50%;
        position: relative;
      }
    }

    &-content_black {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include m1024 {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      background-color: $black;
      padding: 40px 40px 10px 40px;

      h1 {
        color: $white;
        font-size: 40px;
        line-height: 1em;
        font-style: italic;
        font-weight: bold;
        margin-bottom: 0.7325em;

        @include m1761 {
          font-size: 68.64px;
        }
      }

      h2 {
        color: $white;
        font-size: 20px;
        line-height: 1.266em;
        font-style: italic;
        font-weight: bold;

        @include m1761 {
          font-size: 37.68px;
        }
      }

      &-cta {
        color: black;
        padding-bottom: 40px;
      }
    }

    &-content_white {
      width: 100%;
      font-size: 1.46vw;
      padding-left: 7.3vw;
      align-self: center;
    }
  }

  @include m1023 {
    &-slider {
      .slick-list {
        width: 100%;
        height: auto;
      }

      .slick-dots {
        bottom: -5px;

        li {
          margin: 0;
        }
      }

      .slick-dots li.slick-active button:before {
        font-size: 13px;
      }

      .slick-dots li button:before {
        font-size: 5px;
      }
    }

    &-container {
      flex-direction: column;
      margin-bottom: 40px;

      &-imgText {
        width: 67.5vw;
        height: 67.5vw;
        min-height: auto;
        min-width: auto;
      }

      &-imgAlone {
        min-height: auto;
        min-width: auto;
      }

      &-ContainerDouble {
        width: 100vw;
        height: 167.5vw;
      }

      &-content_black {
        width: 100%;
        height: auto;
        min-height: 100vw;
        min-height: auto;
        min-width: auto;
        padding: 12.5vw 6.25vw 3.7vw;

        h1 {
          font-size: 28px;
          font-size: 8.75vw;
          line-height: 0.9286em;
          margin-bottom: 6.25vw;
        }

        h2 {
          font-size: 21px;
          font-size: 6.5625vw;
          line-height: 1.238em;
          margin-bottom: 11.7vw;
        }

        &-cta {
          color: white;
          padding-bottom: 6.25vw;
        }
      }

      &-content_white {
        width: 100%;
        font-size: 16px;
        padding: 20px;
        align-self: center;
      }
    }

    &-button {
      margin-bottom: 0;
    }
  }
}
