@import 'node_modules/sass-math/math';

$animation_duration_clip : 0.7s;
$animation_duration: 0.35s;

$animation_duration_submobile_clip : 0.7s;
$animation_duration_submobile: 0.35s;

$margin_right : 200px;
$margin_subright : 200px;
$margin_right_mobile : 50px;
$nb_spot: 20;
$pi: 3.1415926536;
// $easeIn : cubic-bezier(0.63, -0.06, 0.65, 2.3);
$easeIn : ease;
$easeInReverse :cubic-bezier(0.4, -1.73, 1, 0.32);

@function makepolygon($decalage, $margin) {
    $val: 0% 0%,
    calc(100% - #{$margin}) 0;

    @for $i from 1 through $nb_spot {
        $percent: ($i - 1) / ($nb_spot - 1);
        $val: #{$val},
        calc(100% + #{$decalage * sin(180deg * $percent)} - #{$margin}) #{$percent * 100%};
    }

    $val: #{$val},
    calc(100% - #{$margin}) 100%,
    0% 100%;

    @return $val;
}

@mixin clippath($decalage :0, $margin: 0) {
    clip-path: polygon(makepolygon($decalage, $margin));
}

@function makepolygonH($decalage, $margin) {
    $val: 100% 0%,
    100% calc(100% - #{$margin});

    @for $i from 1 through $nb_spot {
        $percent: ($i - 1) / ($nb_spot - 1);
        $val: #{$val},
        #{100% - $percent * 100%} calc(100% + #{$decalage * sin(180deg * $percent)} - #{$margin});
    }

    $val: #{$val},
    0% calc(100% - #{$margin}),
    0% 0%;

    @return $val;
}

@mixin clippathH($decalage :0, $margin: 0) {
    clip-path: polygon(makepolygonH($decalage, $margin));
}

@mixin transition_item() {
    @for $i from 1 through 30 {
        &:nth-child(#{$i}) span {
            transition-delay: #{$animation_duration * 0.5 + $i * $animation_duration * 0.25};
        }

        &:nth-child(#{$i}):before {
            transition-delay: #{$animation_duration * 0.25 + $i * $animation_duration * 0.25};
        }

        &:nth-child(#{$i}):after {
            transition-delay: #{$animation_duration * 0.5 + $i * $animation_duration * 0.25},
            #{$animation_duration};
        }
    }
}

.menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    pointer-events: none;

    &_burger {
        width: 29px;
        height: 34px;
        position: fixed;
        right: 5.8566%;
        top: 45px;
        z-index: 2;
        pointer-events: all;

        &_line {
            position: absolute;
            left: 0;
            right: 0;
            height: 2px;
            background-color: $white;
            border-bottom: solid 2px $black;
            box-sizing: content-box;
            transform: rotate(0deg);
            transition: top #{$animation_duration * 0.5} ease #{$animation_duration * 0.5},
            border #{$animation_duration * 0.5} ease #{$animation_duration * 0.5},
            transform #{$animation_duration * 0.5} ease;

            &--top {
                top: 0;
            }

            &--middle {
                top: 15px;
            }

            &--bottom {
                top: 30px;
            }
        }
    }

    &_socials {
        position: absolute;
        bottom: 14.5%;
        right: 5.8566%;
        height: 60px;
        a {
            margin-left: 20px;
            display: block;
            background-color: $white;
            mask-repeat: no-repeat;
            mask-position: center center;
            mask-size: contain;
            width: 29px;
            height: 100%;
            opacity: 0;
            transition: opacity 0.35s ease;
        }
        [title="Linkedin"] {
            mask-image: url("/assets/img/picto_linkedin.svg");
            padding-left: -9999px;
        }
        [title="Instagram"] {
            mask-image: url("/assets/img/picto_instagram.svg");
        }
    }

    &_lang {
        // position: absolute;
        // bottom: 8.4%;
        // right: calc(#{$margin_right} + 45px);
        // display: flex;
        // font-size: 23px;
        display: flex;
        padding: 10px 45px 0 0;
        font-size: 23px;
        justify-content: flex-end;

        &--sep {
            &:before {
                content: "-";
                margin: 0 5px;
            }
        }

        &--btn {
            &[data-lang-active='false'] {
                opacity: 0.25;
            }
        }
    }

    &_main {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: $white;
        transform: translateX(-100%);
        transition: transform #{$animation_duration} ease,
        clip-path #{$animation_duration} $easeInReverse;
        padding-left: 80px;
        padding-top: 45px;
        padding-right: $margin_right;
        width: calc(415px + #{$margin_right});
        @include clippath($margin_right, $margin_right);

        &_liste:hover &_item {
            opacity: 0.125;

            &:hover,
            &--selected {
                opacity: 1;
            }
        }

        &_item {
            font-size: 23px;
            font-style: oblique;
            font-weight: 700;
            position: relative;
            margin-bottom: -1px;
            opacity: 1;
            transition: opacity 0.35s ease;

            &:before,
            &:after {
                content: "";
                position: absolute;
                height: 1px;
                width: 0%;
                left: 0;
                background-color: $black;
                opacity: 1;
                transition: width #{$animation_duration} ease;
            }

            &:after {
                transition: width #{$animation_duration} ease,
                opacity 0s ease;
            }

            &:before {
                top: 0;
            }

            &:after {
                bottom: 0;
            }

            button,
            span {
                text-transform: uppercase;
                width: 100%;
                text-align: left;
            }

            button {
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    border-top: solid 2px $black;
                    border-right: solid 2px $black;
                    top: 50%;
                    right: 49px;
                    transform: translateY(-50%) scaleX(1) rotate(45deg);
                    transition: transform 0.15s ease;
                }
            }

            span {
                padding: 38px 0 35px 15px;
                display: block;
                opacity: 0;
                transition: opacity #{$animation_duration} ease;
            }

            @include transition_item();
        }
    }


    &_second {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: calc(56.2% + #{$margin_subright});
        overflow: hidden;
        background-color: $purple;
        color: $white;
        transform: translateX(-100%);
        transition: transform #{$animation_duration} ease,
        left 0s ease 0s,
        clip-path #{$animation_duration} $easeInReverse;
        @include clippath($margin_subright, $margin_subright);
        padding-left: 62px;
        padding-top: 95px;
        padding-right: $margin_subright;

        @include m1024 {
            &_liste:hover &_item {
                opacity: 0.125;

                &:hover,
                &--selected {
                    opacity: 1;
                }
            }
        }

        &_item {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 900;
            transition: opacity 0.35s ease;

            a {
                padding: 30px 0 29px 15px;
                display: block;
                opacity: 0;
                transition: opacity #{$animation_duration} ease #{$animation_duration};
            }
        }
    }

    &_second_mobile {
        display: none;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $black;
        opacity: 0;
        transition: opacity 0.35s ease;
    }

    &--open {
        pointer-events: all;

        &:before {
            opacity: 0.8;
        }

        .menu {
            &_main {
                transition: transform 0.35s ease, clip-path $animation_duration_clip $easeIn;
                transform: translateX(0);
                @include clippath(0px, $margin_right);

                &_item {

                    &:before,
                    &:after {
                        width: 100%;
                    }

                    span {
                        opacity: 1;
                    }
                }
            }

            &_socials a {
                opacity: 1;
                pointer-events: all;
            }

            &_second {
                left: 415px;
                transition: transform #{$animation_duration} ease,
                left 0s ease #{$animation_duration},
                clip-path #{$animation_duration} $easeInReverse;
            }

            &_burger {
                &_line {
                    border-width: 0px;
                    transition: top #{$animation_duration * 0.5} ease,
                    border #{$animation_duration * 0.5} ease,
                    transform #{$animation_duration * 0.5} ease #{$animation_duration * 0.5};

                    &--top {
                        top: 15px;
                        transform: rotate(45deg);
                    }

                    &--middle {
                        top: 15px;
                        transform: rotate(45deg);
                    }

                    &--bottom {
                        top: 15px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    &--subopen {
        .menu {
            &_main {
                &_item {
                    opacity: 0.125;

                    &--selected {
                        opacity: 1;

                        button {
                            &:after {
                                transform: translate(50%, -50%) scaleX(-1) rotate(45deg);
                            }
                        }
                    }
                }
            }

            &_second--subopen {
                left: 415px;
                transform: translateX(0);
                transition: transform 0.35s ease,
                left 0s ease #{$animation_duration},
                clip-path $animation_duration_clip $easeIn;
                @include clippath(0px, $margin_subright);

                a {
                    opacity: 1;
                }
            }
        }
    }

    @include m1023 {
        &_burger {
            width: 14px;
            height: 34px;
            right: 23px;
            top: 22px;

            &_line {
                &--top {
                    top: 0;
                }

                &--middle {
                    top: 6px;
                }

                &--bottom {
                    top: 12px;
                }
            }
        }

        &_main {
            overflow: auto;
            padding-top: 58px;
            padding-left: 0;
            padding-right: $margin_right_mobile;
            width: calc(246px + #{$margin_right_mobile});
            @include clippath($margin_right_mobile, $margin_right_mobile);

            &_item {
                font-size: 18px;
                transition: margin 0s ease $animation_duration_submobile;
                opacity: 1 !important;

                button {
                    &:after {
                        width: 12px;
                        height: 12px;
                        right: 19px;
                        transform: translateY(-50%) scaleY(1) rotate(135deg);
                    }
                }

                span {
                    padding: 20px 0 20px 28px;
                }

                &:before,
                &:after {
                    z-index: -1;
                }

                &--selected {
                    &:after {
                        opacity: 0;
                        transition: width #{$animation_duration} ease,
                        opacity 0s ease 0s !important;
                    }
                }

                &--selected+& {
                    transition: margin 0s ease 0s;
                    margin-top: -$margin_right_mobile;
                }
            }
        }

        &_second {
            display: none;
        }

        &_second_mobile {
            display: block;
            max-height: 0;
            overflow: hidden;
            transition: max-height $animation_duration_submobile ease, clip-path $animation_duration_submobile_clip $easeInReverse, padding-bottom 0s linear $animation_duration_submobile;
            background-color: $purple;
            @include clippathH($margin_right_mobile, $margin_right_mobile);

            ul {
                margin: 20px 23px;
            }

            span {
                font-style: normal;
                color: $white;
                font-size: 16px;
                font-weight: 600;
                padding: 19px 0 19px 28px;
            }
        }

        &_socials {
            bottom: 10%;
            right: 24px;
            height: 50px;
            a {
                width: 21px;
            }
        }

        &_lang {
            bottom: 4.3%;
            right: calc(#{$margin_right_mobile} + 17px);
            font-size: 18px;
    
            &--sep {
                &:before {
                    margin: 0 5px;
                }
            }
        }

        &--open {
            .menu {
                &_main {
                    @include clippath(0px, $margin_right_mobile);
                }

                &_burger {
                    &_line {
                        &--top {
                            top: 6px;
                        }

                        &--middle {
                            top: 6px;
                        }

                        &--bottom {
                            top: 6px;
                        }
                    }
                }
            }
        }

        &--subopen {
            .menu {
                &_main {
                    &_item {
                        &--selected {
                            button {
                                &:after {
                                    transform: translateY(-0%) scaleY(-1) rotate(135deg);
                                }
                            }
                        }
                    }
                }

                &_second_mobile--subopen {
                    @include clippathH(0px, $margin_right_mobile);
                    transition: max-height $animation_duration_submobile ease, clip-path $animation_duration_submobile_clip $easeIn, padding-bottom 0s linear 0s;
                    max-height: 500px;
                    padding-bottom: $margin_right_mobile;
                }
            }
        }
    }
}