@mixin animate_set($listes, $timer: 0.35s) {
    $result: ();

    @each $item in $listes {
        @if $item=="opacity" {
            $result: append($result, opacity $timer ease, comma);
            @include animate_opacity;
        }

        @else if $item=="slide-up" {
            $result: append($result, transform $timer ease, comma);
            @include animate_slideup;
        }

        @else if $item=="slide-down" {
            $result: append($result, transform $timer ease, comma);
            @include animate_slidedown;
        }

        @else if $item=="margin-up" {
            $result: append($result, margin $timer ease, comma);
            @include animate_marginup;
        }

        @else if $item=="margin-down" {
            $result: append($result, margin $timer ease, comma);
            @include animate_margindown;
        }
    }

    transition: $result;
}

@mixin animate_opacity {
    opacity: 0;

    .blocInView & {
        opacity: 1;
    }
}

@mixin animate_slideup {
    transform: translateY(50px);

    .blocInView & {
        transform: translateY(0);
    }
}

@mixin animate_slidedown {
    transform: translateY(-50px);

    .blocInView & {
        transform: translateY(0);
    }
}

@mixin animate_marginup {
    margin-top: 50px;

    .blocInView & {
        margin-top: 0px;
    }
}

@mixin animate_margindown {
    margin-top: -50px;

    .blocInView & {
        margin-top: 0px;
    }
}

@mixin delayTransition($timer: 0.35s, $start: 0s, $max : 30) {
    @for $i from 1 through $max {
        &:nth-child(#{$i}) {
            transition-delay: $start + $timer * ($i - 1);
        }
    }
}