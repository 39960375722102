.t-contact {
    margin-top: 81px;
    margin-bottom: 208px;

    .headerType1 + & {
        margin-top: -9px;
    }

    &_intro {
        font-size: 20px;
        line-height: 1.3em;
        margin-bottom: 58px;

        opacity: 0;
        transform: translateY(100px);
        transition: opacity 0.35s ease, transform 0.35s ease;

        .blocInView & {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &_container {
        display: flex;
        justify-content: space-between;
    }

    &_col {
        flex: 0 0 48.5%;

        &>* {
            opacity: 0;
            transform: translateY(100px);
            transition: opacity 0.35s ease, transform 0.35s ease;

            .blocInView & {
                opacity: 1;
                transform: translateY(0);
            }
        }

        &:nth-child(1) {
            &>* {
                @include delayTransition(0.15s, 0.20s, 10);
            }
        }

        &:nth-child(2) {
            &>* {
                @include delayTransition(0.15s, 0.65s, 10);
            }
        }
    }

    &_required {
        font-size: 18px;
        margin: 13px 10px;
    }

    &_button {
        display: flex;
        justify-content: flex-end;
        margin-top: 36px;

        opacity: 0;
        transform: translateY(100px);
        transition: opacity 0.35s ease 0.95s, transform 0.35s ease 0.95s;

        .blocInView & {
            opacity: 1;
            transform: translateY(0);
        }

        &_done{
            font-size: 20px;
            font-weight: 400;
            font-style: oblique;
            color: $purple;
        }
    }

    @include m1023 {
        margin-top: 26px;
        margin-bottom: 80px;

        .headerType1 + & {
            margin-top: 26px;
        }

        &_intro {
            font-size: 16px;
            line-height: 1.4em;
            margin-bottom: 50px;
        }

        &_container {
            flex-wrap: wrap;
        }

        &_col {
            flex: 0 0 100%;
            margin-top: 24px;

            &:first-child {
                margin-top: 0;
            }

            &--recaptcha{
                iframe{
                    transform:scale(0.77);
                }
            }
        }

        &_required {
            font-size: 15px;
            margin: 8px 12px;
        }

        &_button {
            margin-top: 27px;
        }
    }
}